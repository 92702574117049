import React, { useState, useContext, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../Context/ThemeContext";
// import bgSolution from "../../../public/bg-solution.jpg";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Parallax } from "react-parallax";
import { useSolutions } from "../../Context/SolutionsContext";
import { useServices } from "../../Context/ServicesContext";
import "./services.css";
import DaaS from "../../components/DaaS/DaaS";

function Services() {
  const { darkMode } = useContext(ThemeContext);
  const [selectedService, setSelectedService] = useState(null);
  const titleRef = useRef(null);
  const cardRefs = useRef([]);
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const [loadingSolutions, setLoadingSolutions] = useState(true);
  const navigate = useNavigate();
  const { solutions, getAllSolutions } = useSolutions();
  const { services, getServices } = useServices();
  const { solutionsSecction, getSolutionsSecction } = useSolutions();

  useEffect(() => {
    getSolutionsSecction();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getAllSolutions();
      setLoadingSolutions(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!loadingSolutions) {
      const query = new URLSearchParams(location.search);
      const serviceName = query.get("service");
      if (serviceName) {
        const solution = solutions.find((sol) => sol.title === serviceName);
        if (solution) {
          setSelectedService(serviceName);
          getServices(solution.id);
        }
      }
    }
  }, [location.search, solutions, loadingSolutions]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleClick = (serviceName, index) => {
    setSelectedService(serviceName);
    navigate(`?service=${serviceName}`);
    setTimeout(() => {
      cardRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const handleBackClick = () => {
    setSelectedService(null);
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (selectedService === null && titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedService]);

  return (
    <>
      <span ref={titleRef}></span>
      <Navbar />
      <div className={`${selectedService ? "hidden" : "h-[100vh]"}`}>
        {solutionsSecction.map((solutionSecction, index) => (
        <Parallax
          // key={index}
          bgImage={solutionSecction.image_background}
          // blur={{ min: -2, max: 4 }}
          strength={500}
        >
          <div
            className={`principal flex bg-black/65  w-full justify-between ${
              darkMode ? " text-white" : ""
            }`}
            ref={containerRef}
          >
            <div className="txt flex flex-col justify-center items-center w-full rounded-r-full h-[100vh]">
              <div
                className={`txt-c w-[80%] h-[50vh] rounded-r-full flex flex-col justify-center ${
                  darkMode ? "" : ""
                } ${
                  isVisible
                    ? "animate-fade-left animate-once animate-duration-[5000ms] animate-normal animate-fill-backwards"
                    : ""
                }`}
              >
                <h1
                  className={`text-6xl mb-4 w-[80%] font-semibold ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  {solutionSecction.title}
                  {/* Portafolio de Soluciones GTI */}
                </h1>
                <p
                  className={`w-[60%] text-xl whitespace-pre-line text-justify font-medium ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  {solutionSecction.description}
                  {/* En GTI contamos con un portafolio corporativo integral,
                  soportado por productos y soluciones lideres del mercado, que
                  cubren las necesidades dinámicas del Negocio de nuestros
                  clientes. <br />
                  <br />
                  Desde el suministro de cómputo para usuario final hasta la
                  implementación de infraestructura, seguridad y servicios, GTI
                  se convierte en su principal aliado tecnológico, permitiéndole
                  apalancar sus procesos internos, garantizando productividad e
                  innovación en las Compañías. */}
                </p>
              </div>
            </div>
          </div>
        </Parallax>
        ))}
      </div>
      <div
        className={`xaas-container w-full flex flex-col items-center justify-center ${
          darkMode ? "bg-[#424242]" : ""
        }`}
      >
        <div className="titles w-[78%] pt-10">
          <h1
            className={`text-4xl mb-10 text-[#0e4980] font-semibold ${
              darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
            } ${selectedService ? "hidden" : ""}`}
          >
            Servicios y Soluciones Tecnológicas diseñadas para cada necesidad.
          </h1>
          <h1
            className={`text-2xl text-[#0e4980] font-semibold ${
              darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
            } ${selectedService ? "hidden" : ""}`}
          >
            Estás buscando:
          </h1>
        </div>
        <div className="contenedor-cards w-[80%] flex flex-col my-8 items-center justify-center mb-8">
          <div className="cardSolutions">
            {Array.isArray(solutions) &&
              solutions.map((solution, index) => (
                <div
                  key={solution.id}
                  ref={(el) => (cardRefs.current[index] = el)}
                  className={`card flex flex-col justify-center items-center rounded-t-[8px] rounded-b-[8px] ${
                    selectedService === solution.title
                      ? "expanded"
                      : selectedService === null
                      ? index % 4 === 0 || index % 4 === 3
                        ? "card-large"
                        : "card-small"
                      : "hidden"
                  } ${
                    darkMode
                      ? "bg-gray-600 text-white border-gray-800"
                      : "bg-[#F1F8FE]"
                  }`}
                  onClick={() => handleClick(solution.title, index)}
                >
                  <img src={solution.image_solution} alt="" />
                  <div className="cardExtended">
                    <h1
                      className={`h-[60px] ${
                        darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                      }`}
                    >
                      {solution.title}
                    </h1>
                    <p
                      className={`w-[90%] ${
                        darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                      } ${
                        selectedService === solution.title ? "expanded" : ""
                      }`}
                    >
                      {solution.description}
                    </p>
                    <div className="w-full flex justify-end">
                      <button
                        className={`${
                          darkMode
                            ? "text-[#ffffff] bg-gray-900"
                            : "bg-[#0e4980] text-[#ffffff]"
                        } ${
                          selectedService === solution.title
                            ? "bg-red-900 hidden"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(solution.title, index);
                        }}
                      >
                        Saber más
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="w-[80%] flex justify-center">
          <div className={`w-full flex flex-wrap gap-8`}>
            {selectedService &&
            selectedService === "DaaS (Dispositivo Como Servicio)" ? (
              <DaaS />
            ) : selectedService && services.length > 0 ? (
              services.map((service, index) => (
                <div className="" key={service.id}>
                  <div
                    className={`w-[100%] service-item ${
                      selectedService ? "" : "hidden"
                    }`}
                  >
                    <div className="service-details">
                      <div
                        className={`service-detail-card ${
                          index % 2 === 0
                            ? `bg-[#cadfea] ${darkMode ? "bg-gray-500" : ""}`
                            : `bg-[#9ec5da] ${darkMode ? "bg-gray-700" : ""}`
                        }`}
                      >
                        <div className="service-card w-full h-full flex items-center">
                          <div className="service-detail-icon-wrapper">
                            <img
                              src={service.image_service}
                              className="service-detail-icon"
                            />
                          </div>
                          <div className="service-detail-content">
                            <h3
                              className={`service-detail-info ${
                                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                              }`}
                            >
                              {service.name_service}
                            </h3>
                            <p
                              className={`service-detail-description text-justify ${
                                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                              }`}
                            >
                              {service.description_service}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : selectedService && services.length === 0 ? (
              <p className={`text-xl ${darkMode ? "text-white" : ""}`}>
                No hay servicios disponibles para la solución seleccionada.
              </p>
            ) : null}
            {selectedService && (
              <div className="w-full flex justify-center mb-4">
                <button
                  onClick={handleBackClick}
                  className={`back-button ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  Volver a todas las soluciones
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
