import React, { useEffect, useContext } from "react";
import "./cicloExperiencia.css";
import { ThemeContext } from "../../Context/ThemeContext";
import { Parallax } from "react-parallax";
import { useContentAboutUs } from "../../Context/AuthAboutUsContext";

const CicloExperiencia = () => {
  const { darkMode } = useContext(ThemeContext);
  const { cycleContent, getCycleExperienceContent } = useContentAboutUs();
  const { cycleImages, getCycleExperienceImages } = useContentAboutUs();

  useEffect(() => {
    getCycleExperienceContent();
    getCycleExperienceImages();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".outer-circle");
      const translateValue = window.innerWidth <= 430 ? 150 : 200;
      elements.forEach((el, index) => {
        el.style.transform = `rotate(${
          window.scrollY / 10 + index * (360 / cycleImages.length)
        }deg) translate(${translateValue}px) rotate(-${
          window.scrollY / 10 + index * (360 / cycleImages.length)
        }deg)`;
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [cycleImages.length]);

  return (
    <div className="">
      {cycleContent.map((cycle, index) => (
        <Parallax bgImage={cycle.image_background} strength={400}>
          <div
            className={`todo bg-black/45 w-full flex h-full pt-[4%] pb-[4%]`}
          >
            <div className="customer-experience-cycle" key={index}>
              <div className="cycle-container">
                <div className={`center-circle ${darkMode ? "" : ""}`}>
                  <img
                    src={darkMode ? cycle.image_dark_cycle : cycle.image_cycle}
                    alt=""
                  />
                </div>
                {cycleImages.map((cycleImage, index) => (
                  <div
                    key={index}
                    className={`outer-circle ${
                      darkMode
                        ? "bg-[#424242e1] border-gray-600"
                        : "bg-[#3a9ec9ec]"
                    }`}
                  >
                    <img
                      src={cycleImage.images_cycle}
                      className="w-[32px]"
                      alt={cycleImage.title}
                    />
                    <p>{cycleImage.title}</p>
                    <span>{cycleImage.cycle_sequence}</span>
                  </div>
                ))}
              </div>
              <div
                className={`description ${
                  darkMode ? "bg-[#424242e1]" : "bg-[#113d69f4]"
                }`}
              >
                <h2
                  className={`ml-[15%] text-2xl py-8 w-[80%] font-semibold ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  {cycle.title}
                </h2>
                <p
                  className={`w-[75%] ml-[15%] text-justify text-md font-medium pb-[5%] ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  {cycle.description_one}
                </p>
                <p
                  className={`w-[75%] ml-[15%] text-justify text-md pb-4 font-medium ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  {cycle.description_two}
                </p>
              </div>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
  );
};

export default CicloExperiencia;
