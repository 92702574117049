import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useServicesSuperAdmin } from "../../Context/ServicesContextSuperAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import { ThemeContext } from "../../Context/ThemeContext";

function FormCreateServicesSuperAdmin() {
  const { register, handleSubmit, setValue } = useForm();
  const { createServices, getService, updateService } = useServicesSuperAdmin();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false); // Estado para determinar si se está actualizando o creando

  useEffect(() => {
    const loadService = async () => {
      if (params.id) {
        console.log("Loading service with params.id:", params.id); // Agregar depuración para params.id
        try {
          const service = await getService(params.id);
          console.log("Service loaded:", service); // Verificar datos del servicio

          if (service && Object.keys(service).length > 0) {
            setValue("name_service", service.name_service);
            setValue("description_service", service.description_service);
            if (service.image_service) {
              setImagePreview(service.image_service); // Establecer la URL de la imagen existente
            }
            setIsUpdating(true); // Cambiar el estado a actualizando
          } else {
            console.log("Service data is empty or invalid");
          }
        } catch (error) {
          console.error("Error loading service:", error);
        }
      }
    };
    loadService();
  }, [params.id, setValue, getService]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("name_service", data.name_service);
    formData.append("description_service", data.description_service);
    formData.append("solutionId", params.id); // Asociar el servicio con la solución respectiva
    console.log("Form data submitted:", formData);

    if (image) {
      formData.append("image_service", image);
    }
    try {
      if (isUpdating) {
        await updateService(params.id, formData);
      } else {
        await createServices(formData);
      }
      navigate("/solutions-panel-super-admin");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };

  return (
    <>
      {isUpdating ? (
        <div>
          <NavbarSuperAdmin />
          <div
            className={`w-full flex justify-center h-full gap-20 ${
              darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
            }`}
          >
            <div className="h-full pt-[8%] pb-[4%]">
              <form
                onSubmit={onSubmit}
                className={`h-full p-6 rounded shadow-md ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
              >
                <h2 className="text-2xl font-bold mb-4">
                  {isUpdating ? "Actualizar servicio" : "Crear nuevo servicio"}
                </h2>
                <p className="mb-4">
                  LLena todos los campos para{" "}
                  {isUpdating ? "actualizar" : "agregar"} el servicio.
                </p>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="title"
                  >
                    Nombre del servicio:
                  </label>
                  <input
                    type="text"
                    id="title"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      darkMode ? "bg-[#212121]" : "bg-white"
                    }`}
                    placeholder="Añade el nombre"
                    {...register("name_service")}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="image"
                  >
                    Imagen:
                  </label>
                  {imagePreview && (
                    <div className="mt-4 flex justify-center">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="w-[160px]"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    id="image"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={handleImageChange}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="description"
                  >
                    Descripción:
                  </label>
                  <textarea
                    id="description"
                    rows={5}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      darkMode ? "bg-[#212121]" : "bg-white"
                    }`}
                    placeholder="Añade la descripción"
                    {...register("description_service")}
                  ></textarea>
                </div>

                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {isUpdating ? "Actualizar" : "Agregar"}
                  </button>
                  {isUpdating && (
                    <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                      <Link to="/solutions-panel-super-admin">Cancelar</Link>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <FooterAmin />
        </div>
      ) : (
        <div>
          <div className={`w-full flex justify-center gap-20 `}>
            <div className={`pt-[7%] `}>
              <form
                onSubmit={onSubmit}
                className={`h-full p-6 rounded shadow-md ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
              >
                <h2 className="text-2xl font-bold mb-4">
                  {isUpdating ? "Actualizar servicio" : "Crear nuevo servicio"}
                </h2>
                <p className="mb-4">
                  LLena todos los campos para{" "}
                  {isUpdating ? "actualizar" : "agregar"} el servicio.
                </p>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="title"
                  >
                    Nombre del servicio:
                  </label>
                  <input
                    type="text"
                    id="title"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      darkMode ? "bg-[#212121]" : "bg-white"
                    }`}
                    placeholder="Añade el nombre"
                    {...register("name_service")}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="image"
                  >
                    Imagen:
                  </label>
                  {imagePreview && (
                    <div className="mt-4 flex justify-center">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="w-[160px]"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    id="image"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={handleImageChange}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="description"
                  >
                    Descripción:
                  </label>
                  <textarea
                    id="description"
                    rows={5}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      darkMode ? "bg-[#212121]" : "bg-white"
                    }`}
                    placeholder="Añade la descripción"
                    {...register("description_service")}
                  ></textarea>
                </div>

                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {isUpdating ? "Actualizar" : "Agregar"}
                  </button>
                  {isUpdating && (
                    <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                      <Link to="/solutions-panel">Cancelar</Link>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FormCreateServicesSuperAdmin;
