import React from "react";
import SolutionsContent from "../components/SolutionContent/SolutionsContent";
import AliadosFabricantes from "../components/AliadosFabricantes/AliadosFabricantes";
import ServiciosTiNegocio from "../components/ServiciosTINegocio/ServiciosTiNegocio";
import Footer from "../components/Footer/Footer";
import SubContentHome from "../components/SubContentHome/SubContentHome";
import Navbar from "../components/Navbar/Navbar";
import BotWSP from "../components/BotWSP/BotWSP";
import AboutUsHome from "../components/AboutUs/AboutUsHome";

function HomeGTI() {
  return (
    <>
      <Navbar />
      <AboutUsHome/>
      <SolutionsContent />
      <SubContentHome />
      <AliadosFabricantes />
      <ServiciosTiNegocio />
      <BotWSP />
      <Footer />
    </>
  );
}

export default HomeGTI;
