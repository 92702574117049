import { createContext, useContext, useState } from "react";
import { protectedRouteSuperAdmin } from "../api/authUser";

const ServicesContextSuperAdmin = createContext();

export const useServicesSuperAdmin = () => {
  const context = useContext(ServicesContextSuperAdmin);

  if (!context) {
    throw new Error("useServices must be used within a ServicesProvider");
  }
  return context;
};

export const ServicesSuperAdminProvider = ({ children }) => {
  const [services, setServices] = useState([]);

  // obtener todas los servicios
  const getServices = async (solutionId) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/services/solution/${solutionId}`
      );
      setServices(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // obtener servicio especifica
  const getService = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/services/${id}`);
      const serviceData = res.data;
      return serviceData;
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };

  // crear servicios
  const createServices = async (solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/services",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // actualizar los servicios
  const updateService = async (id, solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/services/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // eliminar un servicio
  const deleteServices = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(`/services/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ServicesContextSuperAdmin.Provider
      value={{
        services,
        getService,
        getServices,
        createServices,
        updateService,
        deleteServices,
      }}
    >
      {children}
    </ServicesContextSuperAdmin.Provider>
  );
};
