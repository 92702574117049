import axios from "axios";
import instance from "./axios.js";

export const protectedRoute = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return clienteAxios;
  } else {
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer null`,
      },
    });
    return clienteAxios;
  }
};

export const protectedRouteSuperAdmin = () => {
  const token = localStorage.getItem("Supertoken");
  if (token) {
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return clienteAxios;
  } else {
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer null`,
      },
    });
    return clienteAxios;
  }
};

export const registerRequest = (user) => instance.post("/registerAdministrator", user);
export const updateCredentialsRequest = (id, user) => instance.put(`/update-credentials/${id}`, user);
export const loginRequest = (user) => instance.post("/loginAdministrator", user);
export const verifyTokenRequest = () => protectedRoute().get("/verify");

export const registerRequestSuperAdmin = (user) => instance.post("/registerSuperAdmin", user);
export const loginRequestSuperAdmin = (user) => instance.post("/loginSuperAdmin", user);
export const verifyTokenRequestSuperAdmin = () => protectedRouteSuperAdmin().get("/verify-superAdmin");