import React, { useState, useEffect, useRef, useContext } from "react";
import { Parallax } from "react-parallax";
import { useContentAboutUs } from "../../Context/AuthAboutUsContext";
import { ThemeContext } from "../../Context/ThemeContext";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import copas30 from "../../../public/copas30.png";
import logo from "../../../public/Logo-GTI.png";
import bg30 from "../../../public/bg30.png";
import "./aboutUs.css";

function AboutUsHome() {
  const { firstContent, getFirstContent } = useContentAboutUs();
  const { darkMode } = useContext(ThemeContext);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  //funcion para el contado de estadisticas
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, //velocidad con la cual se dispara la animacion
  });

  useEffect(() => {
    getFirstContent();
  }, []);

  return (
    <>
      <div className="">
        {/* {firstContent.map((content, index) => ( */}
          <Parallax
            bgImage={bg30}
            // blur={{ min: -2, max: 4 }}
            strength={50}
          >
            <div
              className={`principal  h-[100vh] bg-black/40 flex w-full justify-center items-center animate-fade-down animate-once animate-duration-[8000ms] animate-normal animate-fill-backwards ${
                darkMode ? "text-white" : ""
              }`}
              ref={containerRef}
            >
              {/* <div className="txt flex flex-col justify-center items-center w-full rounded-r-full animate-fade-right animate-once animate-duration-[8000ms] animate-normal animate-fill-backwards h-[85vh]">
                <div
                  className={`txt-c w-[90%] h-[50vh] rounded-r-full flex flex-col pt-16 justify-center ${
                    darkMode ? "" : ""
                  } ${
                    isVisible
                      ? "animate-fade-left animate-once animate-duration-[5000ms] animate-normal animate-fill-backwards"
                      : ""
                  }`}
                >
                  <h1
                    className={`text-7xl mb-4 w-[80%] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {content.title}
                  </h1>
                  <h2
                    className={`text-5xl mb-4 w-[80%] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {content.sub_title}
                  </h2>
                  <p
                    className={`w-[70%] text-3xl font-medium text-justify ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {content.description}
                    Somos GTI - Alberto Álvarez López SAS, empresa líder en
                    integración tecnológica, contamos con el respaldo de los
                    principales fabricantes a nivel mundial y una trayectoria de
                    30 años en el país, apoyamos la transformación digital
                    basados en la innovación y la co-creación de valor para
                    nuestros clientes.
                  </p>
                </div>
              </div> */}
              <div className="text-center">
                <div className="relative image30">
                  {/* <img
                    src={copas30}
                    className="w-[40%] mx-auto copas"
                    alt="Copas 30"
                  /> */}
                </div>
                <h1
                  className={`ti-cu text-6xl font-cursive mb-4 ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  Treinta Años de Éxitos e Innovación
                </h1>
                <p
                  className={`p30 text-3xl ${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  Estamos orgullosos de celebrar tres décadas de crecimiento,
                  innovación y éxito.
                  <br />
                  A todos nuestros colaboradores, clientes y socios:
                  <br />
                  ¡Gracias por acompañarnos en este increíble viaje!
                </p>
              </div>
            </div>
          </Parallax>
        {/* ))} */}
      </div>
      <div
        ref={ref}
        className={`estadisticas w-full py-8 flex ${
          darkMode
            ? "bg-gray-900 text-[#ffffff]"
            : "bg-[#F1F8FE] text-[#0e4980]"
        }`}
      >
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={30} duration={12} suffix="+" />}
          </h2>
          <p className="text-xl">Años de experiencia</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={1000} duration={12} suffix="+" />}
          </h2>
          <p className="text-xl">Clientes</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={6000} duration={12} suffix="+" />}
          </h2>
          <p className="text-xl">Dispositivos en modelo DaaS e IaaS</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={100} duration={12} suffix="+" />}
          </h2>
          <p className="text-xl">Colaboradores</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={100} duration={12} suffix="%" />}
          </h2>
          <p className="text-xl">Cubrimiento a nivel nacional</p>
        </div>
      </div>
    </>
  );
}

export default AboutUsHome;
