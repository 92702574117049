import { useContext } from "react";
import LogoGTI from "../../../public/Logo-GTI.png";
import LogoGTIBlack from "../../../public/GTIblackMenu.png";
import "./footer.css";
import { ThemeContext } from "../../Context/ThemeContext";

const Footer = () => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <footer className={`${darkMode ? "bg-gray-900 text-white" : ""}`}>
      <div className="w-full flex justify-center flex-col items-center">
        <div className="w-full flex justify-center flex-col items-center">
          <h2
            className={`title text-4xl py-2 font-semibold ${
              darkMode ? "text-white" : "text-[#0E4980]"
            }`}
          >
            ¡Es el momento de hacer su negocio más productivo!
          </h2>
        </div>
        <div className="w-full text-center">
          <div className="w-full bg-footer mt-4">
            <button className="flex justify-center items-center bg-[#ffff] font-bold text-xl text-[#113d69] px-4 p-4 rounded h-[50px] w-[180px]">
              <a href="/contact">Contáctanos</a>
            </button>
          </div>
        </div>
        <div className="w-[80%] grid grid-cols-1 md:grid-cols-3 gap-4 py-8 justify-center">
          <div className="h-[100px] flex justify-center items-center">
            <a href="/">
              <img
                src={darkMode ? LogoGTIBlack : LogoGTI}
                className="footer-logo"
                alt="Logo GTI"
              />
            </a>
          </div>
          <div className="tel text-[#0E4980]">
            <h3
              className={`font-semibold ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              Manizales
            </h3>
            <p
              className={`text-sm ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              GTI – Alberto Álvarez López – Calle 64A #21-10.
            </p>
            <p className={`${darkMode ? "text-white" : "text-[#0E4980]"}`}>
              Edificio Portal del Cable. Local 18
            </p>
            <p className={`${darkMode ? "text-white" : "text-[#0E4980]"}`}>
              Manizales – Caldas.
            </p>
          </div>
          <div className="tel text-[#0E4980]">
            <h3
              className={`font-semibold ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              Pereira
            </h3>
            <p
              className={`text-sm ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              GTI – Alberto Álvarez López – Calle 8 #19-12.
            </p>
            <p
              className={`text-sm ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              Edificio Trípoli Oficina 504. Cra 19#8-13.
            </p>
            <p className={`${darkMode ? "text-white" : "text-[#0E4980]"}`}>
              Pereira – Risaralda.
            </p>
          </div>
          <div></div>
          <div className="teln text-[#0E4980] md:text-left mb-4 md:mb-0 w-[400px]">
            <div
              className={`font-semibold ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              Mesa de servicios
            </div>
            <div
              className={` text-sm ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              Número telefónico: (+57) +6 885 2626 – Ext 105/106
            </div>
            <div
              className={`text-sm ${
                darkMode ? "text-white" : "text-[#0E4980]"
              }`}
            >
              Línea Nacional: 01 8000 423 810
            </div>
            <div className={`${darkMode ? "text-white" : "text-[#0E4980]"}`}>
              Web: https://mesadeservicio.gti.net.co
            </div>
            <div className={`${darkMode ? "text-white" : "text-[#0E4980]"}`}>
              Correo electrónico: soporte@gti.net.co
            </div>
          </div>
          <div className={`tel ${darkMode ? "text-white" : "text-[#0E4980]"}`}>
            <p className="font-bold">Política de SAGRILAFT Y PTEE</p>
            <p><a href="/sagrilaft-ptee">SAGRILAFT y PTEE</a></p>
          </div>
        </div>
        <div className="w-[80%] h-[1px] bg-[#0E4980]"></div>
        <div className="mt-4 mb-4 text-center text-[#0E4980]">
          <p
            className={`font-bold ${
              darkMode ? "text-white" : "text-[#0E4980]"
            }`}
          >
            Copyright © GTI
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
