import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterAmin from "../../components/Footer/FooterAmin";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContentAboutUsSuperAdmin } from "../../Context/AuthAboutUsContextSuperAdmin";

function ValuesContentFormSuperAdmin() {
  const { register, handleSubmit, setValue } = useForm();
  const { getValuesContentById, updateValuesContent, createValuesContent } =
    useContentAboutUsSuperAdmin();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [imageValues, setImageValues] = useState(null);
  const [imageValuesPreview, setImageValuesPreview] = useState(null);
  const [imageValuesDark, setImageValuesDark] = useState(null);
  const [imageValuesDarkPreview, setImageValuesDarkPreview] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      if (params.id) {
        try {
          const content = await getValuesContentById(params.id);

          if (content && Object.keys(content).length > 0) {
            setValue("title", content.title);
            setValue("values_about_us", content.values_about_us);
            setValue("description_values", content.description_values);

            if (content.values_image) {
              setImageValuesPreview(content.values_image);
            }
            if (content.values_dark_image) {
              setImageValuesDarkPreview(content.values_dark_image);
            }

            setIsUpdating(true);
          }
        } catch (error) {
          console.error("Error loading content:", error);
        }
      }
    };
    loadContent();
  }, [params.id, setValue, getValuesContentById]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("values_about_us", data.values_about_us);
    formData.append("description_values", data.description_values);

    if (imageValues) {
      formData.append("values_image", imageValues);
    }
    if (imageValuesDark) {
      formData.append("values_dark_image", imageValuesDark);
    }

    try {
      console.log("Form data being submitted:", formData);
      if (isUpdating) {
        await updateValuesContent(params.id, formData);
      } else {
        await createValuesContent(formData);
      }
      navigate("/about-us-panel-super-admin");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  const handleImageValuesChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageValues(file);
      setImageValuesPreview(URL.createObjectURL(file));
    } else {
      setImageValues(null);
      setImageValuesPreview(null);
    }
  };

  const handleImageValuesDarkChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageValuesDark(file);
      setImageValuesDarkPreview(URL.createObjectURL(file));
    } else {
      setImageValuesDark(null);
      setImageValuesDarkPreview(null);
    }
  };

  return (
    <>
      <NavbarSuperAdmin />
      <div
        className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className={`h-full pt-[12%] pb-[4%]`}>
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar Contenido" : "Añade un nuevo Contenido"}
            </h2>
            <p className="mb-4">
              LLena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} el Contenido.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="mission"
              >
                Titulo:
              </label>
              <input
                type="text"
                id="mission"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="vission"
              >
                Nombre del valor:
              </label>
              <input
                type="text"
                id="vission"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("values_about_us")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description_mission"
              >
                Descripción:
              </label>
              <textarea
                id="description_mission"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description_values")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_mission"
              >
                Imagen:
              </label>
              {imageValuesPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageValuesPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_mission"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageValuesChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_mission_dark"
              >
                Imagen (Modo oscuro):
              </label>
              {imageValuesDarkPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageValuesDarkPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_mission_dark"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageValuesDarkChange}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/about-us-panel-super-admin">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default ValuesContentFormSuperAdmin;
