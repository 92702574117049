import React, { useContext, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ThemeContext } from "../../Context/ThemeContext";
import "./serviciosTi.css";
import { useContentHome } from "../../Context/AuthContentHomeContext";

function ServiciosTiNegocio() {
  const { darkMode } = useContext(ThemeContext); 
  const { servicesTi, getServicesTi } = useContentHome();

  useEffect(() => {
    getServicesTi();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 4000,
    verticalSwiping: true,
    arrows: false,
    pauseOnHover: false
  };

  return (
    <>
      <div
        className={`full w-full ${
          darkMode ? "bg-gray-800 text-white" : ""
        }`}
      >
        <div className="con w-full flex justify-center">
          <div className="w-[80%]">
            <div className="cont flex justify-center">
              <h1
                className={`title text-4xl mb-4 text-[#0e4980] font-semibold w-[60%] text-center ${
                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                }`}
              >
                Productos de TI pensados específicamente en su negocio.
                 Busquemos juntos la mejor opción
              </h1>
            </div>
            <div>
              <Slider {...settings}>
                {servicesTi.map((product) => (
                  <div
                    className="py-8 bg-[#beddf900] rounded-md"
                    key={product.id}
                  >
                    <div className="w-[80%] ml-[10%]">
                      <div className="cont flex flex-col items-center justify-center">
                        <h1
                          className={`text-[#0e4980] text-2xl font-semibold ${
                            darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                          }`}
                        >
                          {product.sub_title}
                        </h1>
                        <img
                          className="slide-img h-[450px]"
                          src={product.images_services_ti}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiciosTiNegocio;
