import React, { useEffect } from "react";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import { useSuperAdminAuth } from "../../Context/AuthSuperAdminContext";

function PanelAdmin() {
  const { administrators, getAllAdministrators } = useSuperAdminAuth();

  useEffect(() => {
    getAllAdministrators();
  }, []);

  return (
    <>
      <NavbarSuperAdmin />
      <div className="w-full flex flex-col items-center p-6 mt-[10%] h-[70vh]">
        <h1 className="text-2xl font-semibold mb-4">Administradores</h1>
        <table className="w-[80%] bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b text-black">Usuarios</th>
              <th className="px-4 py-2 border-b text-black">Email</th>
              <th className="px-4 py-2 border-b text-black">Acción</th>
            </tr>
          </thead>
          <tbody>
            {administrators.length > 0 ? (
              administrators.map((admin, index) => (
                <tr key={index}>
                  <td className="w-[50%] text-center px-4 py-2 border-b">
                    {admin.username}
                  </td>
                  <td className="w-[50%] text-center px-4 py-2 border-b">
                    {admin.email}
                  </td>
                  <td className="w-full text-center px-4 py-2 border-b">
                    <a
                      className="text-blue-500 hover:text-blue-700"
                      href={`/form-administrator-credentials/${admin.id}`}
                    >
                      Editar
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center p-4">
                  No administrators found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <FooterAmin />
    </>
  );
}

export default PanelAdmin;
