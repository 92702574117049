import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser";

const SolutionsContext = createContext();

export const useSolutions = () => {
  const context = useContext(SolutionsContext);

  if (!context) {
    throw new Error("useSolutions must be used within a SolutionsProvider");
  }
  return context;
};

export const SolutionsProvider = ({ children }) => {
  const [solutions, setSolutions] = useState([]);
  const [solutionsContent, setSolutionsContent] = useState([]);
  const [solutionsSecction, setSolutionsSecction] = useState([]);

  const getAllSolutions = async () => {
    try {
      const res = await protectedRoute().get("/allSolutions");
      setSolutions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutions = async () => {
    try {
      const res = await protectedRoute().get("/solutions");
      setSolutions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolution = async (id) => {
    try {
      const res = await protectedRoute().get(`/solutions/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createSolutions = async (solutionData) => {
    try {
      const res = await protectedRoute().post("/solutions", solutionData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data; // Asegúrate de que esto incluya el ID de la nueva solución
    } catch (error) {
      console.log(error);
    }
  };

  const updateSolution = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(`/solutions/${id}`, solutionData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSolution = async (id) => {
    try {
      const res = await protectedRoute().delete(`/solutions/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //solutions content
  const getSolutionsContent = async () => {
    try {
      const res = await protectedRoute().get("/solutions-content");
      setSolutionsContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutionsContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/solutions-content/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createSolutionsContent = async (solutionData) => {
    try {
      const res = await protectedRoute().post(
        "/solutions-content",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSolutionsContent = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(
        `/solutions-content/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //solutions secction
  const getSolutionsSecction = async () => {
    try {
      const res = await protectedRoute().get("/solutions-secction");
      setSolutionsSecction(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutionsSecctionById = async (id) => {
    try {
      const res = await protectedRoute().get(`/solutions-secction/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createSolutionsSecction = async (solutionData) => {
    try {
      const res = await protectedRoute().post(
        "/solutions-secction",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSolutionsSecction = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(
        `/solutions-secction/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SolutionsContext.Provider
      value={{
        solutions,
        getAllSolutions,
        getSolution,
        getSolutions,
        createSolutions,
        updateSolution,
        deleteSolution,
        solutionsContent,
        getSolutionsContentById,
        getSolutionsContent,
        createSolutionsContent,
        updateSolutionsContent,
        solutionsSecction,
        getSolutionsSecctionById,
        getSolutionsSecction,
        createSolutionsSecction,
        updateSolutionsSecction,
      }}
    >
      {children}
    </SolutionsContext.Provider>
  );
};
