import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomeGTI from "./pages/HomeGTI";
import { ThemeProvider } from "./Context/ThemeContext";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/SolutionsGTI/Services";
import Contact from "./pages/Contact/Contact";
import RegisterUser from "./pages/RegisterUser/RegisterUser";
import LoginUser from "./pages/LoginUser/LoginUser";
import { AuthProvider } from "./Context/AuthUserContext";
import { AuthSuperAdminProvider } from "./Context/AuthSuperAdminContext";
import PanelAddSolutions from "./pages/PanelAddSolutions/PanelAddSolutions";
import FormCreateSolutionsAndServices from "./pages/FormCreateSolutionsAndServices/FormCreateSolutionsAndServices";
import FormCreateServices from "./pages/FormCreateSolutionsAndServices/FormCreateServices";
import ProtectedRoutes from "./ProtectedRoutes";
import ProtectedRouteSuperAdmin from "./ProtectedRouteSuperAdmin";
import { SolutionsProvider } from "./Context/SolutionsContext";
import { ServicesProvider } from "./Context/ServicesContext";
import PanelAdmin from "./pages/PanelAdmin/PanelAdmin";
import PanelAddHome from "./pages/PanelAddHome/PanelAddHome";
import SliderFormHomeContent from "./pages/FormHomeContent/SliderFormHomeContent";
import { ContentHomeProvider } from "./Context/AuthContentHomeContext";
import ManufacturesForm from "./pages/FormHomeContent/ManufacturesForm";
import ManufactureImagesForm from "./pages/FormHomeContent/ManufactureImagesForm";
import AlliesImagesForm from "./pages/FormHomeContent/AlliesImagesForm";
import CycleExperienceContentForm from "./pages/FormHomeContent/CycleExperienceContentForm";
import CycleExperienceImagesForm from "./pages/FormHomeContent/CycleExperienceImagesForm";
import ServicesTiForm from "./pages/FormHomeContent/ServicesTiForm";
import PanelAddAboutUs from "./pages/PanelAddAboutUs/PanelAddAboutUs";
import { ContentAboutUsProvider } from "./Context/AuthAboutUsContext";
import FirstContentForm from "./pages/FormAboutUsContent/FirstContentForm";
import SubContentForm from "./pages/FormAboutUsContent/SubContentForm";
import MissionAndVissionForm from "./pages/FormAboutUsContent/MissionAndVissionForm";
import ValuesContentForm from "./pages/FormAboutUsContent/ValuesContentForm";
import FormSolutionsSecction from "./pages/FormCreateSolutionsAndServices/FormSolutionsSecction";
import FormSolutionsContent from "./pages/FormCreateSolutionsAndServices/FormSolutionsContent";
import { useEffect } from "react";
import { ContactProvider } from "./Context/ContactContext";
import Blog from "./pages/Blog/Blog";
import { BlogProvider } from "./Context/BlogContext";
import FormArticlesBlog from "./pages/Blog/FormArticlesBlog";
import LoadingRoutes from "./LoadingRoutes";
import InfoGTI from "./components/Sagrilaft/InfoGTI";
import NotFound from "./pages/NotFound/NotFound";
import PanelBlog from "./pages/PanelBlog/PanelBlog";
import MembershipsForm from "./pages/FormAboutUsContent/MembershipsForm";
import QualityPolicyForm from "./pages/FormAboutUsContent/QualityPolicyForm";
import LoginSuperUser from "./pages/LoginUser/LoginSuperUser";
import PanelAddSolutionsSuperAdmin from "./pages/PanelAddSolutions/PanelAddSolutionsSuperAdmin";
import PanelAddHomeSuperAdmin from "./pages/PanelAddHome/PanelAddHomeSuperAdmin";
import PanelAddAboutUsSuperAdmin from "./pages/PanelAddAboutUs/PanelAddAboutUsSuperAdmin";
import SliderFormHomeContentSuperAdmin from "./pages/FormHomeContent/SliderFormHomeContentSuperAdmin";
import FormCreateSolutionsAndServicesSuperAdmin from "./pages/FormCreateSolutionsAndServices/FormCreateSolutionsAndServicesSuperAdmin";
import FormSolutionsSecctionSuperAdmin from "./pages/FormCreateSolutionsAndServices/FormSolutionsSectctionSuperAdmin";
import FormSolutionsContentSuperAdmin from "./pages/FormCreateSolutionsAndServices/FormSolutionsContentSuperAdmin";
import FormCreateServicesSuperAdmin from "./pages/FormCreateSolutionsAndServices/FormCreateServicesSuperAdmin";
import ManufacturesFormSuperAdmin from "./pages/FormHomeContent/ManufacturesFormSuperAdmin";
import ManufactureImagesFormSuperAdmin from "./pages/FormHomeContent/ManufactureImagesFormSuperAdmin";
import AlliesImagesFormSuperAdmin from "./pages/FormHomeContent/AlliesImagesFormSuperAdmin";
import CycleExperienceContentFormSuperAdmin from "./pages/FormHomeContent/CycleExperienceContentFormSuperAdmin";
import CycleExperienceImagesFormSuperAdmin from "./pages/FormHomeContent/CycleExperienceImagesFormSuperAdmin";
import ServicesTiFormSuperAdmin from "./pages/FormHomeContent/ServicesTiFormSuperAdmin";
import FirstContentFormSuperAdmin from "./pages/FormAboutUsContent/FirstContentFormSUperAdmin";
import MembershipsFormSuperAdmin from "./pages/FormAboutUsContent/MembershipsFormSuperAdmin";
import QualityPolicyFormSuperAdmin from "./pages/FormAboutUsContent/QualityPolicyFormSuperAdmin";
import SubContentFormSuperAdmin from "./pages/FormAboutUsContent/SubContentFormSuperAdmin";
import MissionAndVissionFormSuperAdmin from "./pages/FormAboutUsContent/MissionAndVissionFormSuperAdmin";
import ValuesContentFormSuperAdmin from "./pages/FormAboutUsContent/ValuesContentFormSuperAdmin";
import FormArticlesBlogSuperAdmin from "./pages/Blog/FormArticlesBlogSuperAdmin";
import PanelBlogSuperAdmin from "./pages/PanelBlog/PanelBlogSuperAdmin";
import { ContentAboutUsSuperAdminProvider } from "./Context/AuthAboutUsContextSuperAdmin";
import Administrator from "./pages/ProfileEditing/ProfilePage";
import ProfilePage from "./pages/ProfileEditing/ProfilePage";
import ProfilePageEditing from "./pages/ProfileEditing/ProfilePageEditing";
import ProfilePageSuperAdmin from "./pages/ProfileEditing/ProfilePageSuperAdmin";
import { ContentHomeSuperAdminProvider } from "./Context/AuthContentHomeContextSuperAdmin";
import { BlogSuperAdminProvider } from "./Context/BlogContextSuperAdmin";
import { SolutionsSuperAdminProvider } from "./Context/SolutionsContextSuperAdmin";
import { ServicesSuperAdminProvider } from "./Context/ServicesContextSuperAdmin";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <AuthProvider>
      <AuthSuperAdminProvider>
        <SolutionsSuperAdminProvider>
          <SolutionsProvider>
            <ServicesSuperAdminProvider>
              <ServicesProvider>
                <ContentHomeProvider>
                  <ContentHomeSuperAdminProvider>
                    <ContentAboutUsSuperAdminProvider>
                      <ContentAboutUsProvider>
                        <ContactProvider>
                          <BlogSuperAdminProvider>
                            <BlogProvider>
                              <BrowserRouter>
                                <ThemeProvider>
                                  <ScrollToTop />
                                  <Routes>
                                    <Route element={<LoadingRoutes />}>
                                      <Route path="/" element={<HomeGTI />} />
                                      <Route
                                        path="/solutions-gti"
                                        element={<Services />}
                                      />
                                      <Route
                                        path="/about-us"
                                        element={<AboutUs />}
                                      />
                                      <Route path="/blog" element={<Blog />} />
                                      <Route
                                        path="/contact"
                                        element={<Contact />}
                                      />
                                      <Route
                                        path="/sagrilaft-ptee"
                                        element={<InfoGTI />}
                                      />
                                      <Route path="*" element={<NotFound />} />
                                    </Route>

                                    <Route
                                      path="/register-user"
                                      element={<RegisterUser />}
                                    />
                                    <Route
                                      path="/login-user"
                                      element={<LoginUser />}
                                    />
                                    <Route
                                      path="/login-super-user"
                                      element={<LoginSuperUser />}
                                    />
                                    {/* Rutas protegidas */}
                                    <Route
                                      element={<ProtectedRouteSuperAdmin />}
                                    >
                                      <Route
                                        path="/panel-super-admin"
                                        element={<PanelAdmin />}
                                      />
                                      <Route
                                        path="/edit-profile-super-admin/:id"
                                        element={<ProfilePageSuperAdmin />}
                                      />
                                      <Route
                                        path="/form-administrator-credentials/:id"
                                        element={<ProfilePageEditing />}
                                      />
                                      <Route
                                        path="/solutions-panel-super-admin"
                                        element={
                                          <PanelAddSolutionsSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/home-panel-super-admin"
                                        element={<PanelAddHomeSuperAdmin />}
                                      />
                                      <Route
                                        path="/about-us-panel-super-admin"
                                        element={<PanelAddAboutUsSuperAdmin />}
                                      />
                                      <Route
                                        path="/slider-home-form-panel-super-admin"
                                        element={
                                          <SliderFormHomeContentSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/sliders-super-admin/:id"
                                        element={
                                          <SliderFormHomeContentSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/solutions-form-panel-super-admin"
                                        element={
                                          <FormCreateSolutionsAndServicesSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/solutions-super-admin/:id"
                                        element={
                                          <FormCreateSolutionsAndServicesSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/solutions-secction-form-panel-super-admin"
                                        element={
                                          <FormSolutionsSecctionSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/solutions-secction-form-panel-super-admin/:id"
                                        element={
                                          <FormSolutionsSecctionSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/solutions-content-form-panel-super-admin"
                                        element={
                                          <FormSolutionsContentSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/solutions-content-form-panel-super-admin/:id"
                                        element={
                                          <FormSolutionsContentSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/services-form-panel-super-admin"
                                        element={
                                          <FormCreateServicesSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/services-super-admin/:id"
                                        element={
                                          <FormCreateServicesSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/manufactures-allies-content-form-panel-super-admin"
                                        element={<ManufacturesFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/manufactures-allies-content-super-admin/:id"
                                        element={<ManufacturesFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/manufactures-form-images-super-admin"
                                        element={
                                          <ManufactureImagesFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/manufactures-images-super-admin/:id"
                                        element={
                                          <ManufactureImagesFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/allies-form-images-super-admin"
                                        element={<AlliesImagesFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/allies-images-super-admin/:id"
                                        element={<AlliesImagesFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/cycle-experience-content-form-super-admin"
                                        element={
                                          <CycleExperienceContentFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/cycle-experience-content-super-admin/:id"
                                        element={
                                          <CycleExperienceContentFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/cycle-experience-images-form-super-admin"
                                        element={
                                          <CycleExperienceImagesFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/cycle-experience-images-super-admin/:id"
                                        element={
                                          <CycleExperienceImagesFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/services-ti-form-super-admin"
                                        element={<ServicesTiFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/services-ti-super-admin/:id"
                                        element={<ServicesTiFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/first-content-about-us-form-super-admin"
                                        element={<FirstContentFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/first-content-about-us-form-super-admin/:id"
                                        element={<FirstContentFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/memberships-content-about-us-form-super-admin"
                                        element={<MembershipsFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/memberships-content-about-us-form-super-admin/:id"
                                        element={<MembershipsFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/quality-policy-content-about-us-form-super-admin"
                                        element={
                                          <QualityPolicyFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/quality-policy-content-about-us-form-super-admin/:id"
                                        element={
                                          <QualityPolicyFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/sub-content-about-us-form-super-admin"
                                        element={<SubContentFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/sub-content-about-us-form-super-admin/:id"
                                        element={<SubContentFormSuperAdmin />}
                                      />
                                      <Route
                                        path="/mission-vission-content-about-us-form-super-admin"
                                        element={
                                          <MissionAndVissionFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/mission-vission-content-about-us-form-super-admin/:id"
                                        element={
                                          <MissionAndVissionFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/values-about-us-form-super-admin"
                                        element={
                                          <ValuesContentFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/values-about-us-form-super-admin/:id"
                                        element={
                                          <ValuesContentFormSuperAdmin />
                                        }
                                      />
                                      <Route
                                        path="/blog-articles-form-super-admin"
                                        element={<FormArticlesBlogSuperAdmin />}
                                      />
                                      <Route
                                        path="/blog-articles-form-super-admin/:id"
                                        element={<FormArticlesBlogSuperAdmin />}
                                      />
                                      <Route
                                        path="/blog-panel-super-admin"
                                        element={<PanelBlogSuperAdmin />}
                                      />
                                      <Route path="*" element={<NotFound />} />
                                    </Route>
                                    <Route element={<ProtectedRoutes />}>
                                      <Route
                                        path="/edit-profile/:id"
                                        element={<Administrator />}
                                      />
                                      <Route
                                        path="/solutions-panel"
                                        element={<PanelAddSolutions />}
                                      />
                                      <Route
                                        path="/home-panel"
                                        element={<PanelAddHome />}
                                      />
                                      <Route
                                        path="/about-us-panel"
                                        element={<PanelAddAboutUs />}
                                      />
                                      <Route
                                        path="/slider-home-form-panel"
                                        element={<SliderFormHomeContent />}
                                      />
                                      <Route
                                        path="/sliders/:id"
                                        element={<SliderFormHomeContent />}
                                      />
                                      <Route
                                        path="/solutions-form-panel"
                                        element={
                                          <FormCreateSolutionsAndServices />
                                        }
                                      />
                                      <Route
                                        path="/solutions/:id"
                                        element={
                                          <FormCreateSolutionsAndServices />
                                        }
                                      />
                                      <Route
                                        path="/solutions-secction-form-panel"
                                        element={<FormSolutionsSecction />}
                                      />
                                      <Route
                                        path="/solutions-secction-form-panel/:id"
                                        element={<FormSolutionsSecction />}
                                      />
                                      <Route
                                        path="/solutions-content-form-panel"
                                        element={<FormSolutionsContent />}
                                      />
                                      <Route
                                        path="/solutions-content-form-panel/:id"
                                        element={<FormSolutionsContent />}
                                      />
                                      <Route
                                        path="/services-form-panel"
                                        element={<FormCreateServices />}
                                      />
                                      <Route
                                        path="/services/:id"
                                        element={<FormCreateServices />}
                                      />
                                      <Route
                                        path="/manufactures-allies-content-form-panel"
                                        element={<ManufacturesForm />}
                                      />
                                      <Route
                                        path="/manufactures-allies-content/:id"
                                        element={<ManufacturesForm />}
                                      />
                                      <Route
                                        path="/manufactures-form-images"
                                        element={<ManufactureImagesForm />}
                                      />
                                      <Route
                                        path="/manufactures-images/:id"
                                        element={<ManufactureImagesForm />}
                                      />
                                      <Route
                                        path="/allies-form-images"
                                        element={<AlliesImagesForm />}
                                      />
                                      <Route
                                        path="/allies-images/:id"
                                        element={<AlliesImagesForm />}
                                      />
                                      <Route
                                        path="/cycle-experience-content-form"
                                        element={<CycleExperienceContentForm />}
                                      />
                                      <Route
                                        path="/cycle-experience-content/:id"
                                        element={<CycleExperienceContentForm />}
                                      />
                                      <Route
                                        path="/cycle-experience-images-form"
                                        element={<CycleExperienceImagesForm />}
                                      />
                                      <Route
                                        path="/cycle-experience-images/:id"
                                        element={<CycleExperienceImagesForm />}
                                      />
                                      <Route
                                        path="/services-ti-form"
                                        element={<ServicesTiForm />}
                                      />
                                      <Route
                                        path="/services-ti/:id"
                                        element={<ServicesTiForm />}
                                      />
                                      <Route
                                        path="/first-content-about-us-form"
                                        element={<FirstContentForm />}
                                      />
                                      <Route
                                        path="/first-content-about-us-form/:id"
                                        element={<FirstContentForm />}
                                      />
                                      <Route
                                        path="/memberships-content-about-us-form"
                                        element={<MembershipsForm />}
                                      />
                                      <Route
                                        path="/memberships-content-about-us-form/:id"
                                        element={<MembershipsForm />}
                                      />
                                      <Route
                                        path="/quality-policy-content-about-us-form"
                                        element={<QualityPolicyForm />}
                                      />
                                      <Route
                                        path="/quality-policy-content-about-us-form/:id"
                                        element={<QualityPolicyForm />}
                                      />
                                      <Route
                                        path="/sub-content-about-us-form"
                                        element={<SubContentForm />}
                                      />
                                      <Route
                                        path="/sub-content-about-us-form/:id"
                                        element={<SubContentForm />}
                                      />
                                      <Route
                                        path="/mission-vission-content-about-us-form"
                                        element={<MissionAndVissionForm />}
                                      />
                                      <Route
                                        path="/mission-vission-content-about-us-form/:id"
                                        element={<MissionAndVissionForm />}
                                      />
                                      <Route
                                        path="/values-about-us-form"
                                        element={<ValuesContentForm />}
                                      />
                                      <Route
                                        path="/values-about-us-form/:id"
                                        element={<ValuesContentForm />}
                                      />
                                      <Route
                                        path="/blog-articles-form"
                                        element={<FormArticlesBlog />}
                                      />
                                      <Route
                                        path="/blog-articles-form/:id"
                                        element={<FormArticlesBlog />}
                                      />
                                      <Route
                                        path="/blog-panel"
                                        element={<PanelBlog />}
                                      />
                                    </Route>
                                  </Routes>
                                </ThemeProvider>
                              </BrowserRouter>
                            </BlogProvider>
                          </BlogSuperAdminProvider>
                        </ContactProvider>
                      </ContentAboutUsProvider>
                    </ContentAboutUsSuperAdminProvider>
                  </ContentHomeSuperAdminProvider>
                </ContentHomeProvider>
              </ServicesProvider>
            </ServicesSuperAdminProvider>
          </SolutionsProvider>
        </SolutionsSuperAdminProvider>
      </AuthSuperAdminProvider>
    </AuthProvider>
  );
}

export default App;
