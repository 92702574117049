import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterAmin from "../../components/Footer/FooterAmin";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useSolutions } from "../../Context/SolutionsContext";

function FormSolutionsSecction() {
  const { register, handleSubmit, setValue } = useForm();
  const {
    getSolutionsSecctionById,
    updateSolutionsSecction,
    createSolutionsSecction,
  } = useSolutions();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false); // Estado para determinar si se está actualizando o creando

  useEffect(() => {
    const loadContent = async () => {
      if (params.id) {
        console.log("Loading service with params.id:", params.id); // Agregar depuración para params.id
        try {
          const slider = await getSolutionsSecctionById(params.id);
          console.log("Service loaded:", slider); // Verificar datos del servicio

          if (slider && Object.keys(slider).length > 0) {
            setValue("title", slider.title);
            setValue("sub_title", slider.sub_title);
            setValue("description", slider.description);
            setValue("title_two", slider.title_two);
            setValue("sub_title_two", slider.sub_title_two);
            if (slider.image_background) {
              setImagePreview(slider.image_background); // Establecer la URL de la imagen existente
            }
            setIsUpdating(true); // Cambiar el estado a actualizando
          } else {
            console.log("Content data is empty or invalid");
          }
        } catch (error) {
          console.error("Error loading service:", error);
        }
      }
    };
    loadContent();
  }, [params.id, setValue, getSolutionsSecctionById]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("sub_title", data.sub_title);
    formData.append("description", data.description);
    formData.append("title_two", data.title_two);
    formData.append("sub_title_two", data.sub_title_two);
    console.log("Form data submitted:", formData);

    if (image) {
      formData.append("image_background", image);
    }
    try {
      if (isUpdating) {
        await updateSolutionsSecction(params.id, formData);
      } else {
        await createSolutionsSecction(formData);
      }
      navigate("/solutions-panel");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };

  return (
    <>
      <NavbarAdmin />
      <div
        className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className={`h-full pt-[12%] pb-[4%]`}>
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md  ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar Contenido" : "Añade un nuevo Contenido"}
            </h2>
            <p className="mb-4">
              LLena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} el Contenido.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Titulo:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title")}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Sub titulo:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("sub_title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Descripción:
              </label>
              <textarea
                id="description"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Titulo inferior:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title_two")}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Sub titulo inferior:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("sub_title_two")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image"
              >
                Imagen de Fondo:
              </label>
              {imagePreview && (
                <div className="mt-4 flex justify-center">
                  <img src={imagePreview} alt="Preview" className="w-[160px]" />
                </div>
              )}
              <input
                type="file"
                id="image"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageChange}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/solutions-panel">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default FormSolutionsSecction;
