import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useBlogSuperAdmin } from "../../Context/BlogContextSuperAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";

const categories = [
  { id: 1, name: "Software" },
  { id: 2, name: "Hardware" },
  { id: 3, name: "Inteligencia Artificial" },
  { id: 4, name: "Desarrollo Web" },
  { id: 5, name: "Cloud" },
  { id: 6, name: "Infraestructura" },
  { id: 7, name: "Seguridad" },
  { id: 8, name: "Redes" },
];

function FormArticlesBlogSuperAdmin() {
  const { register, handleSubmit, setValue, watch } = useForm();
  const { getArticle, updateArticle, createArticle } = useBlogSuperAdmin();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const loadArticle = async () => {
      if (params.id) {
        try {
          const article = await getArticle(params.id);
          if (article) {
            setValue("title", article.title);
            setValue("content", article.content);
            setValue("category", article.category_id);
            if (article.image) {
              setImagePreview(article.image);
            }
            setIsUpdating(true);
          } else {
            console.log("Article data is empty or invalid");
          }
        } catch (error) {
          console.error("Error loading article:", error);
        }
      }
    };

    loadArticle();
  }, [params.id, setValue, getArticle]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("category", data.category);

    if (image) {
      formData.append("image", image);
    }
    try {
      if (isUpdating) {
        await updateArticle(params.id, formData);
      } else {
        await createArticle(formData);
      }
      navigate("/blog-panel-super-admin");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };

  return (
    <>
      <NavbarSuperAdmin />
      <div
        className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className={`w-[60%] h-full pt-[8%] pb-[4%]`}>
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar Artículo" : "Añadir un nuevo Artículo"}
            </h2>
            <p className="mb-4">
              Llena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} el artículo.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Título:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el título"
                {...register("title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="category"
              >
                Categoría:
              </label>
              <select
                id="category"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                {...register("category")}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image"
              >
                Imagen:
              </label>
              {imagePreview && (
                <div className="mt-4 flex justify-center">
                  <img src={imagePreview} alt="Preview" className="w-[160px]" />
                </div>
              )}
              <input
                type="file"
                id="image"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="content"
              >
                Contenido:
              </label>
              <textarea
                id="content"
                rows={10}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el contenido"
                {...register("content")}
              ></textarea>
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/blog-panel-super-admin">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default FormArticlesBlogSuperAdmin;
