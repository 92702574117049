import React, { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser";

export const ContactContext = createContext();

export const useContactContext = () => {
  const context = useContext(ContactContext);

  if (!context) {
    throw new Error(
      "useContactContext must be used within a ContactProvider"
    );
  }
  return context;
};

export const ContactProvider = ({ children }) => {
  const [contactMessage, setContactMessage] = useState(false);

  const sendContactMessage = async (formData) => {
    try {
      const res = await protectedRoute().post("/contact", formData);
      return res.data;
    } catch (error) {
      console.log(error);
      throw new Error("Error sending contact message");
    }
  };

  return (
    <ContactContext.Provider value={{ sendContactMessage }}>
      {children}
    </ContactContext.Provider>
  );
};
