import React, { useState, useEffect, useContext } from "react";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSolutionsSuperAdmin } from "../../Context/SolutionsContextSuperAdmin";
import FormCreateServices from "./FormCreateServices";
import FooterAmin from "../../components/Footer/FooterAmin";
import { ThemeContext } from "../../Context/ThemeContext";

function FormCreateSolutionsAndServicesSuperAdmin() {
  const { register, handleSubmit, setValue } = useForm();
  const { createSolutions, getSolution, updateSolution } = useSolutionsSuperAdmin();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [imageSolution, setImageSolution] = useState(null);
  const [imageSolutionPreview, setImageSolutionPreview] = useState(null); // Estado para la previsualización de image_solution
  const [iconSolution, setIconSolution] = useState(null);
  const [iconSolutionPreview, setIconSolutionPreview] = useState(null); // Estado para la previsualización de icon_solution
  const [isUpdating, setIsUpdating] = useState(false); // Estado para determinar si se está actualizando o creando

  useEffect(() => {
    const loadSolution = async () => {
      if (params.id) {
        const solution = await getSolution(params.id);
        setValue("title", solution.title);
        setValue("description", solution.description);
        if (solution.image_solution) {
          setImageSolutionPreview(solution.image_solution); // Establecer la URL de la imagen existente
        }
        if (solution.icon_solution) {
          setIconSolutionPreview(solution.icon_solution); // Establecer la URL del icono existente
        }
        setIsUpdating(true); // Cambiar el estado a actualizando
      }
    };
    loadSolution();
  }, [params.id, setValue, getSolution]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (imageSolution) {
      formData.append("image_solution", imageSolution);
    }
    if (iconSolution) {
      formData.append("icon_solution", iconSolution);
    }

    if (params.id) {
      await updateSolution(params.id, formData);
    } else {
      await createSolutions(formData);
    }
    navigate("/solutions-panel-super-admin");
  });

  const handleImageSolutionChange = (e) => {
    const file = e.target.files[0];
    setImageSolution(file);
    if (file) {
      setImageSolutionPreview(URL.createObjectURL(file)); // Crear una URL para la previsualización
    } else {
      setImageSolutionPreview(null);
    }
  };

  const handleIconSolutionChange = (e) => {
    const file = e.target.files[0];
    setIconSolution(file);
    if (file) {
      setIconSolutionPreview(URL.createObjectURL(file)); // Crear una URL para la previsualización
    } else {
      setIconSolutionPreview(null);
    }
  };

  return (
    <>
      <NavbarSuperAdmin />
      <div
        className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className="h-full pt-[8%] pb-[4%]">
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar solución" : "Crear nueva solución"}
            </h2>
            <p className="mb-4">
              LLena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} la solución.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Titulo:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el titulo"
                {...register("title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="imageSolution"
              >
                Imagen:
              </label>
              {imageSolutionPreview && ( // Mostrar la imagen previsualizada si está disponible
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageSolutionPreview}
                    alt="Preview"
                    className="w-[200px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="imageSolution"
                className="shadow appearance-none border rounded w-full mt-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageSolutionChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="iconSolution"
              >
                Icono:
              </label>
              {iconSolutionPreview && ( // Mostrar el icono previsualizado si está disponible
                <div className="mt-4 flex justify-center">
                  <img
                    src={iconSolutionPreview}
                    alt="Preview"
                    className="w-[200px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="iconSolution"
                className="shadow appearance-none border rounded w-full mt-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleIconSolutionChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Descripción:
              </label>
              <textarea
                id="description"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description")}
              ></textarea>
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/solutions-panel-super-admin">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
        <div className="h-auto pt-[7%]">
          {params.id && <FormCreateServices />}
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default FormCreateSolutionsAndServicesSuperAdmin;
