import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./login.css";
import { useAuth } from "../../Context/AuthUserContext";
import { useForm } from "react-hook-form";
import FooterAmin from "../../components/Footer/FooterAmin";

function LoginUser() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { signin, isAuthenticated, userErrors } = useAuth();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    signin(data);
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home-panel");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex items-center justify-center h-[93vh] bg-gray-100 dark:bg-gray-900">
          <div className="content-login mx-auto w-[20%] p-6 bg-white rounded-md shadow-md dark:bg-gray-800">
            <div className="space-y-2 text-center">
              <h1 className="text-3xl font-bold text-[#0e4980]">
                Inicio de sesión
              </h1>
              <p className="text-gray-500 dark:text-gray-400">
                Inicia sesión para comenzar.
              </p>
            </div>
            {userErrors && (
                <div className="flex flex-col justify-center items-center w-2/3">
                  {userErrors.map((error, index) => (
                    <p
                      key={index}
                      className="text-red-500 text-xl font-bold text-left  w-full"
                    >
                      {error}
                    </p>
                  ))}
                </div>
              )}
            <div className="space-y-4 mt-6">
              <div className="space-y-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Correo:
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register("email", { required: true })}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu correo"
                />
                {errors.email && (
                  <p className="text-red-500">
                    Se requiere correo electrónico*
                  </p>
                )}
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Contraseña:
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  {...register("password", { required: true })}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu contraseña"
                />
                {errors.password && (
                  <p className="text-red-500">Se requiere contraseña*</p>
                )}
              </div>
              <button
                type="submit"
                className="w-full rounded-md bg-[#0e4980] py-2 px-4 text-white hover:bg-[#4e87bc]"
              >
                Iniciar sesión
              </button>
            </div>
          </div>
        </div>
      </form>
      <FooterAmin />
    </>
  );
}

export default LoginUser;
