import { createContext, useContext, useState } from "react";
import { protectedRouteSuperAdmin } from "../api/authUser";

const SolutionsContextSuperAdmin = createContext();

export const useSolutionsSuperAdmin = () => {
  const context = useContext(SolutionsContextSuperAdmin);

  if (!context) {
    throw new Error("useSolutions must be used within a SolutionsProvider");
  }
  return context;
};

export const SolutionsSuperAdminProvider = ({ children }) => {
  const [solutions, setSolutions] = useState([]);
  const [solutionsContent, setSolutionsContent] = useState([]);
  const [solutionsSecction, setSolutionsSecction] = useState([]);

  const getAllSolutions = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/allSolutions");
      setSolutions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutions = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/solutions");
      setSolutions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolution = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/solutions/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createSolutions = async (solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/super-admin-solutions",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data; // Asegúrate de que esto incluya el ID de la nueva solución
    } catch (error) {
      console.log(error);
    }
  };

  const updateSolution = async (id, solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-solutions/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSolution = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(
        `/super-admin-solutions/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //solutions content
  const getSolutionsContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/solutions-content");
      setSolutionsContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutionsContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/solutions-content/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createSolutionsContent = async (solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/solutions-content",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSolutionsContent = async (id, solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-solutions-content/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //solutions secction
  const getSolutionsSecction = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/solutions-secction");
      setSolutionsSecction(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutionsSecctionById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/solutions-secction/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const createSolutionsSecction = async (solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/solutions-secction",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSolutionsSecction = async (id, solutionData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-solutions-secction/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SolutionsContextSuperAdmin.Provider
      value={{
        solutions,
        getAllSolutions,
        getSolution,
        getSolutions,
        createSolutions,
        updateSolution,
        deleteSolution,
        solutionsContent,
        getSolutionsContentById,
        getSolutionsContent,
        createSolutionsContent,
        updateSolutionsContent,
        solutionsSecction,
        getSolutionsSecctionById,
        getSolutionsSecction,
        createSolutionsSecction,
        updateSolutionsSecction,
      }}
    >
      {children}
    </SolutionsContextSuperAdmin.Provider>
  );
};
