import React, { useContext, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { ThemeContext } from "../../Context/ThemeContext";
import Footer from "../../components/Footer/Footer";
import "./contact.css";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import pdf from "./private/Política de Habeas Data GTI Alberto Alvarez López SAS.pdf";
import bgImage from "../../../public/bg-contact.jpeg";
import { useContactContext } from "../../Context/ContactContext";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";  // Importa SweetAlert2

function Contact() {
  const { darkMode } = useContext(ThemeContext);
  const captcha = useRef(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const { sendContactMessage } = useContactContext(); // Asegúrate de importar correctamente useContactContext

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleCaptchaChange = () => {
    if (captcha.current.getValue()) {
      setIsCaptchaVerified(true);
      setShowCaptchaError(false);
    }
  };

  const onSubmit = async (data) => {
    if (!data.terms) {
      setShowTermsError(true);
      return;
    }
    if (!isCaptchaVerified) {
      setShowCaptchaError(true);
      return;
    }

    try {
      const response = await sendContactMessage(data);
      console.log("Formulario enviado", response);

      Swal.fire({
        icon: 'success',
        title: 'Mensaje enviado',
        text: 'Tu mensaje se ha enviado correctamente.',
      });
      reset();
    } catch (error) {
      console.error("Error al enviar el formulario", error);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className={`content-form pt-[8%] pb-[2%] ${
          darkMode ? "bg-gray-800" : "bg-[#e4e8f0]"
        }`}
      >
        <div className="container mx-auto px-4">
          <div className="container-sub flex flex-col md:flex-row gap-8">
            <div className="form w-full md:w-1/2">
              <div
                className={`rounded-lg border-2 shadow-lg p-8 md:p-12 space-y-6 ${
                  darkMode ? "bg-gray-900 border-gray-900" : "bg-gray-100"
                }`}
              >
                <div className="text-center space-y-2">
                  <h2
                    className={`text-3xl font-bold ${
                      darkMode ? "text-white" : "text-[#113D69]"
                    }`}
                  >
                    Contáctanos
                  </h2>
                  <p
                    className={`${darkMode ? "text-white" : "text-[#113D69]"}`}
                  >
                    Diligencia el formulario y nos pondremos en contacto contigo.
                  </p>
                </div>
                <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label
                        htmlFor="name"
                        className={`block font-semibold ${
                          darkMode ? "text-gray-300" : "text-[#113D69]"
                        }`}
                      >
                        Nombre:
                      </label>
                      <input
                        id="name"
                        {...register("name", { required: "Nombre es requerido" })}
                        placeholder="Ingresa tu nombre"
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                          darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                        }`}
                      />
                      {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
                    </div>
                    <div className="space-y-2">
                      <label
                        htmlFor="email"
                        className={`block font-semibold ${
                          darkMode ? "text-gray-300" : "text-[#113D69]"
                        }`}
                      >
                        Correo:
                      </label>
                      <input
                        id="email"
                        type="email"
                        {...register("email", { required: "Correo es requerido" })}
                        placeholder="Ingresa tu correo"
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                          darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                        }`}
                      />
                      {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label
                        htmlFor="phone"
                        className={`block font-semibold ${
                          darkMode ? "text-gray-300" : "text-[#113D69]"
                        }`}
                      >
                        Teléfono:
                      </label>
                      <input
                        id="phone"
                        {...register("phone", { required: "Teléfono es requerido" })}
                        placeholder="Ingresa tu teléfono"
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                          darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                        }`}
                      />
                      {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
                    </div>
                    <div className="space-y-2">
                      <label
                        htmlFor="organization"
                        className={`block font-semibold ${
                          darkMode ? "text-gray-300" : "text-[#113D69]"
                        }`}
                      >
                        Organización:
                      </label>
                      <input
                        id="organization"
                        {...register("organization", { required: "Organización es requerida" })}
                        placeholder="Ingresa tu organización"
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                          darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                        }`}
                      />
                      {errors.organization && <p className="text-red-500 text-sm">{errors.organization.message}</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label
                        htmlFor="company_size"
                        className={`block font-semibold ${
                          darkMode ? "text-gray-300" : "text-[#113D69]"
                        }`}
                      >
                        Tamaño de la compañía:
                      </label>
                      <select
                        id="company_size"
                        {...register("company_size", { required: "Tamaño de la compañía es requerido" })}
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                          darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                        }`}
                      >
                        <option value="">Selecciona el tamaño</option>
                        <option value="1-10">1-10 empleados</option>
                        <option value="11-50">11-50 empleados</option>
                        <option value="51-200">51-200 empleados</option>
                        <option value="201+">201+ empleados</option>
                      </select>
                      {errors.company_size && <p className="text-red-500 text-sm">{errors.company_size.message}</p>}
                    </div>
                    <div className="space-y-2">
                      <label
                        htmlFor="position"
                        className={`block font-semibold ${
                          darkMode ? "text-gray-300" : "text-[#113D69]"
                        }`}
                      >
                        Cargo:
                      </label>
                      <input
                        id="position"
                        {...register("position", { required: "Cargo es requerido" })}
                        placeholder="Ingresa tu cargo"
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                          darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                        }`}
                      />
                      {errors.position && <p className="text-red-500 text-sm">{errors.position.message}</p>}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label
                      htmlFor="message"
                      className={`block font-semibold ${
                        darkMode ? "text-gray-300" : "text-[#113D69]"
                      }`}
                    >
                      Cuéntanos cómo podemos ayudarte:
                    </label>
                    <textarea
                      id="message"
                      {...register("message", { required: "Mensaje es requerido" })}
                      placeholder="Escribe tu mensaje"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 ${
                        darkMode ? "dark:bg-gray-800 dark:border-gray-700" : ""
                      } min-h-[150px]`}
                    />
                    {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}
                  </div>
                  <div className="flex gap-4">
                    <input
                      type="checkbox"
                      {...register("terms", { required: true })}
                    />
                    <h2>
                      Aceptar términos y condiciones
                      <span className="pl-2">
                        <Link to={pdf} target="_blank" className="text-[#293bff]">
                          declaración de privacidad
                        </Link>
                      </span>
                    </h2>
                  </div>
                  {errors.terms && (
                    <p className="text-red-500 text-sm">
                      Por favor, acepta los términos y condiciones.
                    </p>
                  )}
                  <div className="captcha flex justify-center">
                    <ReCAPTCHA
                      ref={captcha}
                      sitekey="6Lfkkf0pAAAAAPlt5MzqZA7vHz8Xkat8R8iGAsf6"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  {showCaptchaError && (
                    <p className="text-red-500 text-sm">
                      Por favor, completa el captcha.
                    </p>
                  )}
                  <button
                    type="submit"
                    className={`w-full text-white py-2 px-4 rounded-md focus:outline-none focus:ring ${
                      darkMode ? "bg-gray-600" : "bg-[#113D69]"
                    }`}
                  >
                    Enviar
                  </button>
                </form>
              </div>
            </div>
            <div className="location w-full md:w-1/2">
              <div className="rounded-lg overflow-hidden shadow-lg mb-[4%]">
                <h3
                  className={`text-white text-center py-2 ${
                    darkMode ? "bg-gray-900" : "bg-[#113D69]"
                  }`}
                >
                  Ubicados en Manizales
                </h3>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3974.286701066427!2d-75.4880344243233!3d5.057203438462486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e47657e94d0ded5%3A0x3ee57c1a6a235321!2sGTI%20-%20Alberto%20Alvarez%20L%C3%B3pez!5e0!3m2!1ses!2sco!4v1718307197248!5m2!1ses!2sco"
                  width="100%"
                  height="360"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg">
                <h3
                  className={`text-white text-center py-2 ${
                    darkMode ? "bg-gray-900" : "bg-[#113D69]"
                  }`}
                >
                  Ubicados en Pereira
                </h3>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.8113760900214!2d-75.6883491243236!3d4.802417440765963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38876a7616ad0b%3A0xb7ab1cf697a82e83!2sGTI%20-%20Alberto%20Alvarez%20Lopez!5e0!3m2!1ses!2sco!4v1718307435964!5m2!1ses!2sco"
                  width="100%"
                  height="360"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
