import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import GTIlogo from "../../../public/Logo-GTI.png";
import GTIlogoBlack from "../../../public/GTIblackMenu.png";
import BotIcon from "../../../public/bot.png";
import send from "../../../public/send.png";
import "./bot.css";
import { ThemeContext } from "../../Context/ThemeContext";
import pdf from "./private/Política de Habeas Data GTI Alberto Alvarez López SAS.pdf";

function BotWSP() {
  const [history, setHistory] = useState([
    {
      role: "model",
      parts: `Recuerda que al usar el chat aceptas el uso de\ntratamiento de datos. <a href="${pdf}" target="_blank" class="text-link">Leer política de privacidad</a>.\n\nSi necesitas ayuda puedes escribir "Ayuda" para\nrecibir instrucciones.\n\n¿Hay algo en lo que te pueda ayudar?`,
    },
  ]);
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBotResponding, setIsBotResponding] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { darkMode } = useContext(ThemeContext);

  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [history, isModalOpen]);

  const sendMessage = async () => {
    const requestBody = {
      history: history,
      question: message,
    };

    try {
      setIsBotResponding(true);
      const response = await axios.post(
        "https://appser-gti-bk-prod-fwe0e3e9ftaghzeq.eastus2-01.azurewebsites.net/chat",
        // "http://localhost:10101/chat",
        requestBody
      );
      const updatedHistory = response.data.history;
      setHistory(updatedHistory);
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsBotResponding(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
    }, 500); // Duración de la animación
  };

  const minimizeModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const renderMessage = (message) => {
    return (
      <div
        className="text-md"
        dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br/>") }}
      />
    );
  };

  return (
    <>
      {!isModalOpen && (
        <div className="bot fixed z-50 cursor-pointer left-[94%] bottom-[10%]">
          <button onClick={openModal}>
            <img src={BotIcon} className="" alt="whatsapp icon" />
          </button>
        </div>
      )}
      {isModalOpen && (
        <div
          className={`fixed top-0 left-0 w-full h-full flex items-end justify-end bottom-0 ${
            isClosing ? "animate-slide-out-bottom" : "animate-slide-in-bottom"
          }`}
        >
          <div
            className="chat border-2 border-b-0 flex flex-col items-center pb-8 px-4 mb-8 mr-4 rounded-lg w-[30%]"
            style={{
              backgroundColor: darkMode ? "#424242" : "#81c2f5",
              color: darkMode ? "#ffffff" : "#ffff",
            }}
          >
            <div className="w-full flex justify-between  items-center h-[100px]">
              <img
                src={darkMode ? GTIlogoBlack : GTIlogo}
                className="w-[40%]"
                alt=""
              />
              <div
                onClick={minimizeModal}
                className={`flex justify-center space-x-2 w-[20%] z-50 h-[40px] rounded-md cursor-pointer ${
                  darkMode ? "bg-[#c40014]" : "bg-[#c40014]"
                }`}
              >
                <button
                  className={`${
                    darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                  }`}
                >
                  -
                </button>
              </div>
            </div>
            <h1
              className={`text-lg md:text-2xl my-4 ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              }`}
            >
              ¡Charla con nosotros!
            </h1>

            <div
              className={`animate-fade-in flex justify-center items-center min-h-[150px] flex-col w-full rounded-t-md `}
            >
              <SimpleBar autoHide className="w-full" style={{ maxHeight: 300 }}>
                {history.map((item, index) => (
                  <div
                    key={index}
                    className="flex text-justify"
                    ref={index === history.length - 1 ? lastMessageRef : null}
                  >
                    <div
                      className={`p-2 m-2 text-base font-semibold rounded-xl ${
                        item.role === "user"
                          ? darkMode
                            ? "bg-[#333333] text-white"
                            : "bg-[#152d45] text-textBlack"
                          : darkMode
                          ? "bg-[#555555] text-white"
                          : "bg-[#103B66] text-textBlack"
                      }`}
                      style={{
                        alignSelf:
                          item.role === "user" ? "flex-start" : "flex-end",
                      }}
                    >
                      {renderMessage(item.parts)}
                    </div>
                  </div>
                ))}
                {isBotResponding && (
                  <div
                    className={` flex justify-center bg-none text-4xl ${
                      darkMode ? "text-[#ffffff]" : "text-[#345e71]"
                    }`}
                  >
                    <div className="animate-bouncing animate-delay-100 animate-iteration-count-infinite">
                      .
                    </div>
                    <div className="animate-bouncing animate-delay-200 animate-iteration-count-infinite">
                      .
                    </div>
                    <div className="animate-bouncing animate-delay-300 animate-iteration-count-infinite">
                      .
                    </div>
                  </div>
                )}
              </SimpleBar>
            </div>
            <div className="flex justify-center w-full">
              <input
                type="text"
                className="w-[80%] rounded-md outline-none placeholder:text-gold p-2 mt-2 font-bold"
                style={{
                  backgroundColor: darkMode ? "#333333" : "#152d45",
                  color: "#ffffff",
                  border: darkMode ? "1px solid #555555" : "1px solid #103b66",
                }}
                placeholder="¡Pregunta aquí!"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button
                onClick={sendMessage}
                className="w-[15%] flex justify-center items-center h-10 text-[#ffffff] text-base font-bold mt-2 p-2 rounded-md"
                style={{
                  backgroundColor: darkMode ? "#757575" : "#007BC4",
                }}
              >
                <img src={send} className="w-[75%] h-full" alt="send" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BotWSP;
