import { createContext, useContext, useEffect, useState } from "react";
import {
  loginRequest,
  protectedRoute,
  registerRequest,
  updateCredentialsRequest,
  verifyTokenRequest,
} from "../api/authUser";

export const AuthUserContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthUserContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userErrors, setUserErrors] = useState([]);
  const [loading, setLoading] = useState(true);

  const value = {
    user,
    setUser,
  };

  //registro de usuario
  const signup = async (user) => {
    try {
      const res = await registerRequest(user);
      if (res.data === 200) {
        setUser(res.data);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setUserErrors(error);
      console.log(error);
    }
  };

  //inicio de sesión
  const signin = async (user) => {
    try {
      const res = await loginRequest(user);
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        setIsAuthenticated(true);
        setUser(res.data);
        console.log(res);
      } else {
        setIsAuthenticated(false);
        setUserErrors(["Correo o contraseña invalidos*"]);
      }
    } catch (error) {
      console.log(error);
      if (Array.isArray(error.response.data)) {
        return setUserErrors(error.response.data);
      }
      setUserErrors([error.response.data.message]);
    }
  };

  // Actualizar datos del usuario
  const updateUser = async (id, userData) => {
    try {
      const res = await protectedRoute().put(`/update-credentials/${id}`, userData);
      if (res.data) {
        setUser(res.data); // Actualiza los datos del usuario en el estado
        console.log("Usuario actualizado", res.data);
      }
    } catch (error) {
      setUserErrors(["Error al actualizar los datos del usuario"]);
      console.log(error);
    }
  };

  //cerrar sesión
  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser(null);
  };

  //verificación de errores
  useEffect(() => {
    if (userErrors.length > 0) {
      const timer = setTimeout(() => {
        setUserErrors([]);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [userErrors]);

  //verificación del inicio de sesión
  useEffect(() => {
    const checkLogin = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return setUser(null);
      }

      try {
        const res = await verifyTokenRequest(token);
        console.log(res);
        if (!res.data) {
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }

        setIsAuthenticated(true);
        setUser(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
        setUser(null);
        setLoading(false);
      }
    };
    checkLogin();
  }, []);

  return (
    <AuthUserContext.Provider
      value={{
        signup,
        signin,
        updateUser,
        logout,
        loading,
        user,
        isAuthenticated,
        userErrors,
        value
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};
