import { createContext, useContext, useState } from "react";
import { protectedRouteSuperAdmin } from "../api/authUser";

const BlogContextSuperAdmin = createContext();

export const useBlogSuperAdmin = () => {
  const context = useContext(BlogContextSuperAdmin);
  if (!context) {
    throw new Error("useBlog must be used within a BlogProvider");
  }
  return context;
};

export const BlogSuperAdminProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);

  // Obtener todos los artículos
  const getArticles = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/articles");
      setArticles(res.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  // Obtener un artículo específico
  const getArticle = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/articles/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  // Crear un artículo
  const createArticle = async (articleData) => {
    try {
      const res = await protectedRouteSuperAdmin().post("/super-admin-articles", articleData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("Error creating article:", error);
    }
  };

  // Actualizar un artículo
  const updateArticle = async (id, articleData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(`/super-admin-articles/${id}`, articleData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("Error updating article:", error);
    }
  };

  // Eliminar un artículo
  const deleteArticle = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(`/super-admin-articles/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  return (
    <BlogContextSuperAdmin.Provider
      value={{
        articles,
        categories,
        getArticles,
        getArticle,
        createArticle,
        updateArticle,
        deleteArticle,
      }}
    >
      {children}
    </BlogContextSuperAdmin.Provider>
  );
};
