import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./login.css";
import { useForm } from "react-hook-form";
import FooterAmin from "../../components/Footer/FooterAmin";
import { useSuperAdminAuth } from "../../Context/AuthSuperAdminContext";

function LoginSuperUser() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { signin, isAuthenticatedSuperAdmin, userErrors } = useSuperAdminAuth();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    signin(data);
  });

  useEffect(() => {
    if (isAuthenticatedSuperAdmin) {
      navigate("/panel-super-admin");
    }
  }, [isAuthenticatedSuperAdmin, navigate]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex items-center justify-center h-[93vh] bg-gray-100 dark:bg-gray-900">
          <div className="content-login mx-auto w-[20%] p-6 bg-white rounded-md shadow-md dark:bg-gray-800">
            <div className="space-y-2 text-center">
              <h1 className="text-3xl font-bold text-[#0e4980]">
                Inicio de sesión
              </h1>
              <p className="text-gray-500 dark:text-gray-400">
                Inicia sesión para comenzar.
              </p>
            </div>
            <div className="space-y-4 mt-6">
              <div className="space-y-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Correo:
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register("email", { required: true })}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu correo"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Contraseña:
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  {...register("password", { required: true })}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu contraseña"
                />
              </div>
              <button
                type="submit"
                className="w-full rounded-md bg-[#0e4980] py-2 px-4 text-white hover:bg-[#4e87bc]"
              >
                Iniciar sesión
              </button>
            </div>
          </div>
        </div>
      </form>
      <FooterAmin />
    </>
  );
}

export default LoginSuperUser;
