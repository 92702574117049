import "./subContentHome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import Slider from "react-slick";
import { Typography } from "@material-tailwind/react";
import { useContentHome } from "../../Context/AuthContentHomeContext";

function SubContentHome() {
  const { darkMode } = useContext(ThemeContext);
  const { sliders, getSliders } = useContentHome();

  useEffect(() => {
    getSliders();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    pauseOnHover: false,
  };

  return (
    <>
      <div className="sliders-ti w-full h-[100vh] mb-16">
        <div
          className={`w-full flex flex-col justify-center items-center py-8 ${
            darkMode
              ? "bg-gray-900 text-[#ffffff]"
              : "bg-[#F1F8FE] text-[#0e4980]"
          }`}
        >
          <div className="titles-sliders w-[80%] flex flex-col gap-5 justify-center items-center">
            <p className="text-3xl text-center font-semibold">
              Ofrecemos valor constante a nuestros clientes, integrando
              soluciones de vanguardia, soportados en los principales lideres
              tecnológicos a nivel Mundial.
            </p>
            <h2 className="text-4xl text-center font-extrabold">
              Continuemos juntos este viaje de transformación !!{" "}
            </h2>
          </div>
        </div>
        <Slider {...settings}>
          {sliders.map((slider, index) => (
            <div key={index} className="relative w-full">
              <img
                src={slider.image_slider}
                alt={`image ${index + 1}`}
                className="h-[100vh] w-full object-cover"
              />
              <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/55">
                <div className="w-3/4 text-center md:w-2/4 lg:w-[85%]">
                  <Typography
                    variant="h1"
                    color="white"
                    className="title-ti mb-4 text-4xl md:text-4xl lg:text-6xl"
                  >
                    {slider.title}
                  </Typography>
                  <Typography
                    variant="lead"
                    color="white"
                    className="p-title mb-12 opacity-80 text-3xl"
                  >
                    {slider.description}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default SubContentHome;
