import React, { useContext } from "react";
import notFound404 from "../../../public/404.png";
import { ThemeContext } from "../../Context/ThemeContext";

function NotFound() {
  const { darkMode } = useContext(ThemeContext);

  return (
    <>
      <div
        className={`w-full h-[100vh] flex justify-center items-center ${
          darkMode ? "bg-gray-800 text-white" : ""
        }`}
      >
        <div className="w-[80%] flex justify-center items-center">
          <div>
            <h1 className={`text-6xl ${darkMode ? "" : "text-[#103A64]"}`}>
              Pagina No Encontrada
            </h1>
            <p className={`text-xl pt-4`}>
              La ruta que buscas no se encuentra disponible, puedes cambiarla o
              volver a la pagina de inicio.
            </p>
            <div className="w-full flex justify-center pt-10">
              <button
                className={`rounded-md p-2 ${
                  darkMode
                    ? "bg-gray-900 text-white"
                    : "bg-[#103A64] text-white"
                }`}
              >
                <a href="/">Ir al incio</a>
              </button>
            </div>
          </div>
          <div>
            <img src={notFound404} alt="404 no se encuentra" />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
