import React, { useEffect, useState, useRef, useContext } from "react";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import { useBlog } from "../../Context/BlogContext";
import { ThemeContext } from "../../Context/ThemeContext";
import { Parallax } from "react-parallax";
import search from "../../../public/lupa.png";
import searchDark from "../../../public/lupaDark.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function PanelBlog() {
  const { articles, getArticles, getArticle, deleteArticle } = useBlog();
  const [viewingArticle, setViewingArticle] = useState(null);
  const [fullArticle, setFullArticle] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
  const articleRef = useRef(null);
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    getArticles();
  }, [getArticles]);

  const handleReadMore = async (articleId) => {
    const article = await getArticle(articleId);
    setFullArticle(article);
    setViewingArticle(articleId);
    setTimeout(() => {
      if (articleRef.current) {
        articleRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const truncateContent = (content) => {
    const lines = content.split("\n");
    const truncatedLines = lines.slice(0, 6).join("\n");
    return truncatedLines.length > 100
      ? truncatedLines.substring(0, 100) + "..."
      : truncatedLines;
  };

  const filteredArticles = articles.filter((article) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = article.title.toLowerCase().includes(searchLower);
    const contentMatch = article.content.toLowerCase().includes(searchLower);
    const categoryMatch = article.category.toLowerCase().includes(searchLower);
    const dateMatch = new Date(article.created_at)
      .toLocaleDateString()
      .includes(searchTerm);

    return titleMatch || contentMatch || categoryMatch || dateMatch;
  });

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deleteArticle(id);
      getArticles();
      Swal.fire("¡Eliminado!", "El artículo ha sido eliminado.", "success");
    }
  };

  return (
    <>
      <NavbarAdmin />
      <span ref={articleRef}></span>
      <div
        className={`w-full flex flex-col items-center justify-center pt-[6%] ${
          darkMode
            ? "bg-[#424242] text-[#ffffff]"
            : "bg-[#F1F8FE] text-[#0e4980]"
        }`}
      >
        {!viewingArticle && (
          <div
            className={`pt-[2%] w-full flex justify-center ${
              darkMode
                ? "bg-[#424242] text-[#ffffff]"
                : "bg-[#F1F8FE] text-[#0e4980]"
            }`}
          >
            <div className="search w-[80%] flex gap-5">
              <button
                className={`ml-4 p-4 rounded-md ${
                  darkMode
                    ? "bg-gray-900 text-[#ffffff]"
                    : "bg-[#1a4062] text-[#ffffff]"
                }`}
              >
                <Link to="/blog-articles-form">Agregar Nuevo Articulo</Link>
              </button>
              <input
                type="text"
                placeholder="Buscar artículos..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-[40%] search-input p-2 rounded-md ${
                  darkMode ? "text-[#000000]" : "text-[#0e4980]"
                }`}
              />
              <img
                src={darkMode ? searchDark : search}
                className="w-[4%]"
                alt="search"
              />
            </div>
          </div>
        )}

        {viewingArticle ? (
          <div className="article-container">
            <h2 className="article-title">{fullArticle.title}</h2>
            <p className="article-date">
              {new Date(fullArticle.created_at).toLocaleDateString()}
            </p>
            <p className="article-category">{fullArticle.category}</p>
            <img
              src={fullArticle.image}
              alt={fullArticle.title}
              className="article-image"
            />
            <p className="article-content">{fullArticle.content}</p>
            <button className="back-button">
              <a href="/blog-panel">Volver a los artículos</a>
            </button>
          </div>
        ) : (
          <div className="blog-container">
            {filteredArticles.map((article, index) => (
              <div
                key={article.id}
                className={`blog-card ${
                  index % 3 === 2 ? "blog-card-wide" : ""
                } ${
                  darkMode
                    ? "bg-gray-900 text-[#ffffff]"
                    : "bg-[#FFFF] text-[#0e4980]"
                }`}
              >
                <img
                  src={article.image}
                  alt={article.title}
                  className="blog-image"
                />
                <div className="blog-content">
                  <h2 className="blog-title">{article.title}</h2>
                  <p className="blog-date">
                    {new Date(article.created_at).toLocaleDateString()}
                  </p>
                  <p className="blog-category">{article.category}</p>
                  <p className="blog-text">
                    {truncateContent(article.content)}
                  </p>
                  <div className="w-full flex pt-[10%] justify-evenly items-baseline">
                    <span
                      onClick={() => handleReadMore(article.id)}
                      className="read-more-text"
                    >
                      Leer más
                    </span>
                    <button
                      className={`p-2 rounded-md ${
                        darkMode
                          ? "text-[#ffffff] bg-gray-800"
                          : "bg-[#0e4980] text-[#ffffff]"
                      }`}
                    >
                      <Link to={`/blog-articles-form/${article.id}`}>
                        Editar
                      </Link>
                    </button>
                    <button
                      className={`p-2 rounded-md ${
                        darkMode
                          ? "text-[#ffffff] bg-[#7d1e1e]"
                          : "bg-[#800e0e] text-[#ffffff]"
                      }`}
                      onClick={() => handleDelete(article.id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <FooterAmin />
    </>
  );
}

export default PanelBlog;
