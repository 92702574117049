import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser";

const ServicesContext = createContext();

export const useServices = () => {
  const context = useContext(ServicesContext);

  if (!context) {
    throw new Error("useServices must be used within a ServicesProvider");
  }
  return context;
};

export const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]);

  // obtener todas los servicios
  const getServices = async (solutionId) => {
    try {
      const res = await protectedRoute().get(`/services/solution/${solutionId}`);
      setServices(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // obtener servicio especifica
  const getService = async (id) => {
    try {
      const res = await protectedRoute().get(`/services/${id}`);
      const serviceData = res.data;
      return serviceData;
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };

  // crear servicios
  const createServices = async (solutionData) => {
    try {
      const res = await protectedRoute().post("/services", solutionData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // actualizar los servicios
  const updateService = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(`/services/${id}`, solutionData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // eliminar un servicio
  const deleteServices = async (id) => {
    try {
      const res = await protectedRoute().delete(`/services/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ServicesContext.Provider
      value={{
        services,
        getService,
        getServices,
        createServices,
        updateService,
        deleteServices,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

