import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterAmin from "../../components/Footer/FooterAmin";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContentHome } from "../../Context/AuthContentHomeContext";

function ManufacturesForm() {
  const { register, handleSubmit, setValue } = useForm();
  const { createManufactures, getManufacture, updateManufactures } =
    useContentHome();
  const navigate = useNavigate();
  const params = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [isUpdating, setIsUpdating] = useState(false); // Estado para determinar si se está actualizando o creando

  useEffect(() => {
    const loadManufacture = async () => {
      if (params.id) {
        console.log("Loading manufacture with params.id:", params.id); // Agregar depuración para params.id
        try {
          const manufacture = await getManufacture(params.id);
          console.log("Manufacture loaded:", manufacture); // Verificar datos del servicio

          if (manufacture && Object.keys(manufacture).length > 0) {
            setValue("title", manufacture.title);
            setValue("sub_title", manufacture.sub_title);
            setValue("description", manufacture.description);
            setValue("title_manufactures", manufacture.title_manufactures);
            setValue("title_allies", manufacture.title_allies);
            setIsUpdating(true); // Cambiar el estado a actualizando
          } else {
            console.log("Manufacture data is empty or invalid");
          }
        } catch (error) {
          console.error("Error loading service:", error);
        }
      }
    };
    loadManufacture();
  }, [params.id, setValue, getManufacture]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("sub_title", data.sub_title);
    formData.append("description", data.description); 
    formData.append("title_manufactures", data.title_manufactures);
    formData.append("title_allies", data.title_allies);
    console.log("Form data submitted:", formData);

    try {
      if (isUpdating) {
        await updateManufactures(params.id, formData);
      } else {
        await createManufactures(formData);
      }
      navigate("/home-panel");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  return (
    <>
      <NavbarAdmin />
      <div className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}>
        <div className={`h-full pt-[8%] pb-[4%]`}>
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar Contenido" : "Añade un nuevo Slider"}
            </h2>
            <p className="mb-4">
              LLena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} el contenido.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Titulo:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Sub titulo:
              </label>
              <input
                type="text"
                id="sub_title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("sub_title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Descripción:
              </label>
              <textarea
                id="description"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Titulo Fabricantes:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title_manufactures")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Titulo Aliados:
              </label>
              <input
                type="text"
                id="title"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title_allies")}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/home-panel">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default ManufacturesForm;
