import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser";

const ContentHomeContext = createContext();

export const useContentHome = () => {
  const context = useContext(ContentHomeContext);

  if (!context) {
    throw new Error("useContentHome must be used within a ContentHomeProvider");
  }
  return context;
};

export const ContentHomeProvider = ({ children }) => {
  const [sliders, setSliders] = useState([]);
  const [manufactures, setManufactures] = useState([]);
  const [manufacturesImages, setManufacturesImages] = useState([]);
  const [alliesImages, setAlliesImages] = useState([]);
  const [cycleContent, setCycleContent] = useState([]);
  const [cycleImages, setCycleImages] = useState([]);
  const [servicesTi, setServicesTi] = useState([]);

  //Sliders content
  const getSliders = async () => {
    try {
      const res = await protectedRoute().get(`/sliders`);
      setSliders(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSlider = async (id) => {
    try {
      const res = await protectedRoute().get(`/sliders/${id}`);
      const sliderData = res.data;
      return sliderData;
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const createSliders = async (sliderData) => {
    try {
      const res = await protectedRoute().post("/sliders", sliderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSliders = async (id, sliderData) => {
    try {
      const res = await protectedRoute().put(`/sliders/${id}`, sliderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesSliders = async (id) => {
    try {
      const res = await protectedRoute().delete(`/sliders/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Manufactures content
  const getManufactures = async () => {
    try {
      const res = await protectedRoute().get(`/manufactures_and_allies`);
      setManufactures(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getManufacture = async (id) => {
    try {
      const res = await protectedRoute().get(`/manufactures_and_allies/${id}`);
      const manufactureData = res.data;
      return manufactureData;
    } catch (error) {
      console.error("Error fetching manufacture:", error);
    }
  };

  const createManufactures = async (manufactureData) => {
    try {
      const res = await protectedRoute().post(
        "/manufactures_and_allies",
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateManufactures = async (id, manufactureData) => {
    try {
      const res = await protectedRoute().put(
        `/manufactures_and_allies/${id}`,
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesManufactures = async (id) => {
    try {
      const res = await protectedRoute().delete(
        `/manufactures_and_allies/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Manufactures images
  const getManufacturesImages = async () => {
    try {
      const res = await protectedRoute().get(`/manufactures_images`);
      setManufacturesImages(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getManufactureImages = async (id) => {
    try {
      const res = await protectedRoute().get(`/manufactures_images/${id}`);
      const manufactureData = res.data;
      return manufactureData;
    } catch (error) {
      console.error("Error fetching manufacture:", error);
    }
  };

  const createManufacturesImages = async (manufactureData) => {
    try {
      const res = await protectedRoute().post(
        "/manufactures_images",
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateManufacturesImages = async (id, manufactureData) => {
    try {
      const res = await protectedRoute().put(
        `/manufactures_images/${id}`,
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesManufacturesImages = async (id) => {
    try {
      const res = await protectedRoute().delete(`/manufactures_images/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Allies images
  const getAlliesImages = async () => {
    try {
      const res = await protectedRoute().get(`/allies_images`);
      setAlliesImages(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllieImages = async (id) => {
    try {
      const res = await protectedRoute().get(`/allies_images/${id}`);
      const allieData = res.data;
      return allieData;
    } catch (error) {
      console.error("Error fetching allie:", error);
    }
  };

  const createAlliesImages = async (allieData) => {
    try {
      const res = await protectedRoute().post("/allies_images", allieData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateAlliesImages = async (id, allieData) => {
    try {
      const res = await protectedRoute().put(
        `/allies_images/${id}`,
        allieData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesAlliesImages = async (id) => {
    try {
      const res = await protectedRoute().delete(`/allies_images/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // //Cycle experience content
  // const getCycleExperienceContent = async () => {
  //   try {
  //     const res = await protectedRoute().get(`/cycle-experience`);
  //     setCycleContent(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getCycleExperienceContentById = async (id) => {
  //   try {
  //     const res = await protectedRoute().get(`/cycle-experience/${id}`);
  //     const cycleData = res.data;
  //     return cycleData;
  //   } catch (error) {
  //     console.error("Error fetching cycle:", error);
  //   }
  // };

  // const createCycleExperienceContent = async (cycleData) => {
  //   try {
  //     const res = await protectedRoute().post("/cycle-experience", cycleData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateCycleExperienceContent = async (id, cycleData) => {
  //   try {
  //     const res = await protectedRoute().put(
  //       `/cycle-experience/${id}`,
  //       cycleData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const deletesCycleExperienceContent = async (id) => {
  //   try {
  //     const res = await protectedRoute().delete(`/cycle-experience/${id}`);
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //Cycle experience content
  // const getCycleExperienceImages = async () => {
  //   try {
  //     const res = await protectedRoute().get(`/images-cycle-experience`);
  //     setCycleImages(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getCycleExperienceImagesById = async (id) => {
  //   try {
  //     const res = await protectedRoute().get(`/images-cycle-experience/${id}`);
  //     const cycleData = res.data;
  //     return cycleData;
  //   } catch (error) {
  //     console.error("Error fetching cycle:", error);
  //   }
  // };

  // const createCycleExperienceImages = async (cycleData) => {
  //   try {
  //     const res = await protectedRoute().post(
  //       "/images-cycle-experience",
  //       cycleData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateCycleExperienceImages = async (id, cycleData) => {
  //   try {
  //     const res = await protectedRoute().put(
  //       `/images-cycle-experience/${id}`,
  //       cycleData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const deletesCycleExperienceImages = async (id) => {
  //   try {
  //     const res = await protectedRoute().delete(
  //       `/images-cycle-experience/${id}`
  //     );
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //CService ti
  const getServicesTi = async () => {
    try {
      const res = await protectedRoute().get(`/services-ti`);
      setServicesTi(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getServicesTiById = async (id) => {
    try {
      const res = await protectedRoute().get(`/services-ti/${id}`);
      const serviceTiData = res.data;
      return serviceTiData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createServicesTi = async (serviceTiData) => {
    try {
      const res = await protectedRoute().post("/services-ti", serviceTiData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateServicesTi = async (id, serviceTiData) => {
    try {
      const res = await protectedRoute().put(
        `/services-ti/${id}`,
        serviceTiData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesServicesTi = async (id) => {
    try {
      const res = await protectedRoute().delete(`/services-ti/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentHomeContext.Provider
      value={{
        sliders,
        getSlider,
        getSliders,
        createSliders,
        updateSliders,
        deletesSliders,
        manufactures,
        getManufacture,
        getManufactures,
        createManufactures,
        updateManufactures,
        deletesManufactures,
        manufacturesImages,
        getManufactureImages,
        getManufacturesImages,
        createManufacturesImages,
        updateManufacturesImages,
        deletesManufacturesImages,
        alliesImages,
        getAllieImages,
        getAlliesImages,
        createAlliesImages,
        updateAlliesImages,
        deletesAlliesImages,
        // cycleContent,
        // getCycleExperienceContentById,
        // getCycleExperienceContent,
        // createCycleExperienceContent,
        // updateCycleExperienceContent,
        // deletesCycleExperienceContent,
        // cycleImages,
        // getCycleExperienceImagesById,
        // getCycleExperienceImages,
        // createCycleExperienceImages,
        // updateCycleExperienceImages,
        // deletesCycleExperienceImages,
        servicesTi,
        getServicesTiById,
        getServicesTi,
        createServicesTi,
        updateServicesTi,
        deletesServicesTi,
      }}
    >
      {children}
    </ContentHomeContext.Provider>
  );
};
