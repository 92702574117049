import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthUserContext";
import { useForm } from "react-hook-form";
import "./register.css"

export default function RegisterUser() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { signup, isAuthenticated, errors: registerErrors } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate("/login-user");
  }, [isAuthenticated]);

  const onSubmit = handleSubmit(async (values) => {
    signup(values);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
          <div className="content-register mx-auto w-[20%] p-6 bg-white rounded-md shadow-md dark:bg-gray-800">
            <div className="space-y-2 text-center">
              <h1 className="text-3xl font-bold text-[#0e4980]">Registro</h1>
              <p className="text-gray-500 dark:text-gray-400">
                Crea una cuenta para comenzar.
              </p>
            </div>
            <div className="space-y-4 mt-6">
              <div className="space-y-2">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Usuario
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu usuario"
                  {...register("username", { required: true })}
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Correo
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu correo"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Contraseña
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm h-[40px]"
                  placeholder="Ingresa tu contraseña"
                  {...register("password", { required: true })}
                />
              </div>
              <button
                type="submit"
                className="w-full rounded-md bg-[#0e4980] py-2 px-4 text-white hover:bg-[#4e87bc]"
              >
                Registrarse
              </button>
            </div>
            <div className="text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
              ¿Ya tienes una cuenta?{" "}
              <Link
                to="/login-user"
                className="font-medium text-black hover:underline dark:text-white"
              >
                Iniciar sesión
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
